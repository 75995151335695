.tpl__projects {
  .tpl {
    padding: 120px 0; background-color: @colorLightBlack;
    .moreItems { color: @colorLightWhite; font-family: @font-family-default; font-size: 16px; letter-spacing: 1px; line-height: 1.5; margin: 128px auto; }
    .item {
      display: flex; flex-direction: column; justify-content: center; height: 330px; margin-top: 128px;
      .bg { height: 330px; background-position: center; background-size: contain; background-repeat: no-repeat; opacity: 0.7; }
      .wrapper {
         position: absolute;
        .title { font-size: 36px; font-family: @font-family-second; color: @colorLightWhite; margin-bottom: 5px; line-height: 1; letter-spacing: -2px; }
        .period { font-size: 14px; font-family: @font-family-default; color: @colorYellow; font-weight: 600; line-height: 1.5; }
        .subtitle { font-size: 16px; font-family: @font-family-default; color: @colorLightWhite; line-height: 1.5; }
        .btn.btn--lightBlack {
          padding: 12px 24px; letter-spacing: 0; margin-top: 20px; font-size: 14px;
          svg { width: 16px; height: 16px; fill: @colorLightWhite; }
        }
      }
      video { position: absolute; max-width: 100%; opacity: 0.7; }
    }
    .item.item--bigger {
      height: 400px;
      .bg { height: 400px; }
      .wrapper {
        .title.title--bigger { font-size: 48px; margin-bottom: 30px; }
        .subtitle.subtitle--bigger { font-size: 20px; }
      }
    }
  }

  @media @media-s-min {
    .tpl {
      .item {
        .wrapper {
          transform: translateX(-100px);
          .title { font-size: 48px; letter-spacing: -3px; margin-bottom: 15px; }
          .btn.btn--lightBlack { padding: 14px 32px; margin-top: 32px; }
        }
      }
      .item.item--bigger .wrapper {
        transform: translateX(-100px);
        .title.title--bigger { font-size: 72px; }
      }
    }
  }

  @media @media-m-min {
    .tpl {
      .moreItems { margin: 256px auto; }
      .item {
        height: 500px;
        .bg { height: 500px; }
        .wrapper .title { font-size: 64px;  }
      }
      .item.item--bigger {
        height: 700px;
        .bg { height: 700px; }
        .wrapper {
          transform: translateX(-170px);
          .title.title--bigger { font-size: 128px; }
        }
      }
    }
  }

  @media @media-l-min {
    .tpl {
      padding: 240px 0;
      .item {
        .wrapper {
          .subtitle { font-size: 18px; }
        }
      }
      .item.item--bigger {
        .wrapper {
          .title { max-width: 50%; }
          .subtitle.subtitle--bigger { font-size: 24px; }
        }
      }
    }
  }

  @media @media-xl-min {
    .tpl {
      .item {
        margin-top: 250px;
        .wrapper {
          transform: translateX(-170px);
          .subtitle { max-width: 30%; }
        }
        video { opacity: 1; }
        .bg { opacity: 1; }
      }
      .item.item--bigger {
        .wrapper { transform: translateX(-270px); }
      }
    }
  }
}