.btn {
  border: 0; outline: 0; display: inline-block; font-size: 14px; letter-spacing: 2px; border-radius: 16px; padding: 20px 38px; background-color: @colorLightPetrol; text-shadow: 1px 1px 0 #386975; font-family: @font-family-default;
  font-weight: 500; color: @colorLightWhite; box-shadow: -5px -5px 20px @colorSoftLightPetrol, 5px 5px 20px @colorHardLightPetrol; transition: all 0.2s ease-in-out; cursor: pointer; text-transform: uppercase;

  &.btn--lightPetrol {
    background-color: @colorLightPetrol; text-shadow: 1px 1px 0 #386975; font-family: @font-family-default;
    color:@colorLightWhite; box-shadow: -5px -5px 20px @colorSoftLightPetrol, 5px 5px 20px @colorHardLightPetrol;
    &:hover {
      box-shadow: -2px -2px 5px @colorSoftLightPetrol, 2px 2px 5px @colorHardLightPetrol; color: @colorYellow;
    }
    &:active {
      box-shadow: inset 1px 1px 2px @colorHardLightPetrol, inset -1px -1px 2px @colorSoftLightPetrol;
    }
  }

  &.btn--lightBlack {
    background-color: @colorLightBlack; text-shadow: 1px 1px 0 #386975; font-family: @font-family-default;
    color:@colorLightWhite; box-shadow: -5px -5px 15px @colorSoftLightBlack, 5px 5px 15px @colorHardLightBlack;
    &:hover {
      box-shadow: -2px -2px 5px @colorSoftLightBlack, 2px 2px 5px @colorHardLightBlack; color: @colorYellow;
    }
    &:active {
      box-shadow: inset 1px 1px 2px @colorHardLightBlack, inset -1px -1px 2px @colorSoftLightBlack;
    }
  }

  &.btn--lightWhite {
    background-color: #f0f0f0; text-shadow: 1px 1px 0 @colorHardLightWhite; font-family: @font-family-default;
    color:@colorHardLightWhite; box-shadow: -5px -5px 20px @white, 5px 5px 20px @colorHardLightWhite;
    svg { fill: @colorLightPetrol; }
    &:hover {
      box-shadow: -2px -2px 5px @colorSoftLightWhite, 2px 2px 5px @colorHardLightWhite; color: @colorYellow;
      svg { fill: @colorYellow; }
    }
    &:active {
      box-shadow: inset 1px 1px 2px @colorHardLightWhite, inset -1px -1px 2px @colorSoftLightWhite;
    }
  }

  &.btn--cookie {
    padding: 16px; color: @colorYellow; margin: 0 16px;
  }

  &.btn--decline {
    color: @colorLightWhite; font-size: 10px;
  }

  @media @media-l-min {
    font-size: 16px; padding: 32px 64px;
  }

}
