/* =====================================================================================================================
=================================================== MEDIUM CONTAINER ===================================================
===================================================================================================================== */
.container-medium {.containerx-fixed();max-width:100%;}

@media @media-xl-max {
  .container-medium {width:auto;margin-left:50px;margin-right:50px}
}
@media @media-s-max {
  .container-medium {margin-left:10px;margin-right:10px}
}
@media @media-xxl-min {
  .container-medium { width:(1260px + @gridx-gutter) }
}
@media @media-xl-min {
  .container-fluid { width: 100%; }
}