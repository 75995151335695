.tpl__contact {
  .tpl {
    background-color: @colorLightWhite; padding: 100px 0;
    .information {
      padding: 64px 0 16px;
      .name { display: inline-block; font-family: @font-family-default; font-size: 24px; line-height: 1.1; color: @colorLightPetrol; }
      .items {
        display: flex; flex-direction: column; margin: 4px 0;
        .item { font-size: 16px; letter-spacing: 0; line-height: 1.5; margin: 0; color: @colorDarktPetrol; }
        .item:hover,
        .item:active { color: @colorYellow; }
      }
    }
    .socialIcons {
      .social { line-height: 1; padding: 16px; }
      .social + .social { margin-left: 20px; }
      .social svg { width: 16px; height: 16px; }
    }
  }

  @media @media-l-min {
    .tpl { 
      .information { padding: 64px 0; }
    }
  }
}