html { scroll-behavior: smooth; --scrollbarBG: @colorLightBlack; --thumbBG: @colorYellow; }
html, body { line-height: 26px; font-family: @font-family-default, sans-serif; font-size:16px; font-weight: @font-weight-default; letter-spacing: .3px; -moz-osx-font-smoothing:grayscale; -webkit-text-size-adjust:100%; text-rendering:optimizeLegibility; -webkit-font-smoothing:antialiased; padding: 0!important;}
html.overflow,
body.overflow { overflow: unset; height: 100%;}
body {
  display:flex; min-height:100vh; flex-direction:column; position: relative; margin: 0; overflow: overlay; scrollbar-width: thin; scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  &::-webkit-scrollbar { width: 11px; }
  &::-webkit-scrollbar-track { display: none; }
  &::-webkit-scrollbar-thumb { border-radius: 30px 0 0 30px; border-bottom: 12px solid transparent; border-top: 12px solid transparent; border-right: 100px solid @colorYellow; }
}
body *:focus { outline:none; }
* { box-sizing: border-box; margin: 0;}
.h100p {height:100%}
div, section, article, header, figure, nav, footer, label, main, button, aside, form {position:relative}
img {max-width:100%}
a {
  position: relative; text-decoration:none; transition:color .25s ease-out; font-weight: 400;
  &:hover,
  &:focus {text-decoration:none; }
}
p { color: @colorLightWhite; font-size: 16px; letter-spacing: 1px; line-height: 1.5; margin: 5px auto; text-align: justify; hyphens: auto;}

//headings
h1, h2, h3, h4, h5 { font-family: @font-family-default,sans-serif; }
h1 { font-size: 60px; }
h2 { font-size: 48px; }
h3 { font-size: 36px; }
h4 { font-size: 30px; }
h5 { font-size: 24px; }

//cookie bar
.CookieConsent {
  background-color: @colorLightBlack; padding: 16px;
  .content { color: @colorLightWhite; font-size: 16px; letter-spacing: 1px; line-height: 1.5; margin: 0; }
}

@media @media-l-min {
  html, body { font-size: 20px; line-height: 32px; }
  h1 { font-size: 72px; }
  h2 { font-size: 60px; }
  h3 { font-size: 48px; }
  h4 { font-size: 36px; }
  h5 { font-size: 30px; }

  p { font-size: 20px; }

  .CookieConsent .content { font-size: 20px; }
}

// sticky footer
.page-wrap { flex:1 0 auto; overflow: hidden; padding-top: 0; width: 100%; background-color: inherit; animation: pageWrap 1s ease-in; }
body.overlay { transition: all 2s ease-in-out; overflow: hidden; }

//debug lista
#nette-debug:after {
  -webkit-user-drag:element;content:"XS";position:fixed;top:0;left:0;z-index:9999999;background:#cc0000;padding:5px 15px;text-align:center;font-weight:bold;color:#fff;pointer-events:none;
  @media @media-s-min {content:"S"}
  @media @media-m-min {content:"M"}
  @media @media-l-min {content:"L"}
  @media @media-xl-min {content:"XL"}
  @media @media-xxl-min {content:"XXL"}
  &:hover {opacity:.1}
}

//ostatne
::selection { background: @colorLightPetrol; color: @colorLightWhite; }
