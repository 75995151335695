.comp__heading {
  margin-bottom: 32px;
  .heading { display: inline-block; font-family: @font-family-second; font-size: 48px; line-height: 1.1; margin: 0; letter-spacing: -3px; }
  .heading.heading--yellow { color: @colorYellow; }
  .heading.heading--lightPetrol { color: @colorLightPetrol; }
  .heading.heading--darkPetrol { color: @colorDarktPetrol; }

  @media @media-l-min {
    margin-bottom: 0;
    .heading { font-size: 64px; }
  }
}