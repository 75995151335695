.tpl__skills {
  .tpl {
    background-color: @colorLightPetrol; padding: 120px 0 56px;
    .items {
      display: flex; flex-wrap: wrap; justify-content: space-between; margin: 0 0 64px;
      .item {
        flex: 0 0 100%; flex-basis: 100%; color: @colorLightWhite; font-size: 16px; margin-top: 24px; overflow: auto;
        .title { color: @colorLightWhite; font-size: 16px; letter-spacing: 1px; line-height: 1; }
        .progress {
          background-color: @colorDarktPetrol; border-radius: unset; height: 18px; overflow: unset;
          .progress-bar { background-color: @colorLightWhite; overflow: inherit; animation: animateBar 2s ease-in-out; transform: translateY(-10px); }
          &.progress--transparent {
            background-color: @colorLightPetrol;
            .progress-bar { transform: translateY(10px); animation: animateBarTransparent 2s ease-in-out; }
          }
        }
      }
    }
  }

  @media @media-s-min {
    .tpl {
      .items .item {
        flex-basis: calc(50% - 24px); margin-top: 32px;
        .progress { height: 20px; }
      }
    }
  }

  @media @media-m-min {
    .tpl {
      .items .item {
        flex-basis: calc(50% - 48px);
        .title { line-height: 1.5; }
      }
    }
  }

  @media @media-l-min {
    .tpl {
      padding: 240px 0;
      .items .item {
        flex-basis: calc(50% - 64px);
        .title { font-size: 20px; }
      }
    }
  }

  @media @media-l-min {
    .tpl {
      .items {
        margin: 64px 0 96px;
        .item { flex-basis: calc(36% - 64px); }
      }
    }
  }

  @media @media-xl-min {
    .tpl {
      .items .item { flex-basis: calc(33% - 64px); }
    }
  }
}