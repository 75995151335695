//scroll line
@keyframes animation-line {
  0% { left: 80px; }
  100% { left: 0; }
}

@keyframes animation-arrow {
  0% { width: 0; }
  100% { width: 100%; }
}

//shining effect on name
@keyframes shine {
  from { -webkit-mask-position: 150%; }
  to { -webkit-mask-position: -50%; }
}

//loader
@keyframes first {
  0% { fill: transparent; }
  20% { fill: transparent; }
  80% { fill: @colorYellow; }
  100% { fill: @colorYellow; }
}

@keyframes second {
  0% { fill: transparent; }
  40% { fill: transparent; }
  80% { fill: @colorDarktPetrol; }
  100% { fill: @colorDarktPetrol; }
}

@keyframes third {
  0% { fill: transparent; }
  60% { fill: transparent; }
  80% { fill: @colorLightPetrol; }
  100% { fill: @colorLightPetrol; }
}

//progress bars
@keyframes animateBar {
  0% {transform: translate(-100%, -10px); }
  100% {transform: translate(0, -10px); }
}

@keyframes animateBarTransparent {
  0% {transform: translate(-100%, 10px); }
  100% {transform: translate(0, 10px); }
}

@keyframes photoOpacity {
  0% { opacity: 0;}
  100% { opacity: 0.17; }
}

@keyframes pageWrap {
  0% { opacity: 0;}
  100% { opacity: 1; }
}