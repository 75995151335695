.comp__contactForm {
  margin-top: 128px;
  .items {
    display: flex; justify-content: space-between; flex-wrap: wrap;
    .item {
      flex: 0 0 100%; margin-bottom: 32px;
      input, textarea {
        padding: 4px 16px; font-size: 16px; width: 100%; display: block; border-radius: 16px; color: @colorLightPetrol; resize: none;outline: none; border: none; transition: all 0.2s ease-in-out;
        background-color: #f0f0f0; text-shadow: 1px 1px 0 @colorHardLightWhite; font-family: @font-family-default; box-shadow: -5px -5px 20px @white, 5px 5px 20px @colorHardLightWhite;
        &:hover {
          box-shadow: -2px -2px 5px @colorSoftLightWhite, 2px 2px 5px @colorHardLightWhite;
        }
        &:focus {
          box-shadow: inset 1px 1px 2px @colorHardLightWhite, inset -1px -1px 2px @colorSoftLightWhite;
        }
      }
      .labels {
        display: flex;
        label { font-size: 16px; line-height: 1; display: block; color: @colorLightPetrol; }
        label:after { content: '*'; margin-left: 4px; }
        .errorMessage { line-height: 1.5; font-size: 12px; color: red; margin-left: 6px; }
      }
    }
    .item.item--2cols { flex: 0 0 100%; }
  }
  .btn.btn--lightWhite { padding: 16px 48px; color: @colorLightPetrol; margin-top: 16px; }

  @media @media-m-min {
    .items {
      flex-wrap: nowrap;
      .item.item--2cols { flex: 0 0 50%; flex-basis: calc(50% - 16px); }
    }
  }

  @media @media-l-min {
    margin-top: 16px;
  }
}