.comp__cta {
  padding: 50px 0;
  .content {
    p { padding: 32px 0; }
    .btn { padding: 16px 32px; }
  }

  @media @media-l-min {
    padding: 100px 0;
    .content {
      p { padding: 64px 0; }
      .btn { padding: 32px 64px; }
    }
  }
}