.tpl__notFoundPage {
  .tpl {
    background-color: @colorLightBlack;
    .content {
      display: flex; align-items: center; justify-content: center; min-height: 100vh; flex-direction: column;
      p { margin: 20px 0 70px; text-align: center; }
      .title { font-size: 128px; font-weight: 800; line-height: 1; letter-spacing: -1px; font-family: @font-family-default; pointer-events: none;
        color: @colorLightBlack; text-shadow: -5px -5px 15px @colorSoftLightBlack, 5px 5px 15px @colorHardLightBlack; padding: 0 20px; }
    }
  }

  @media @media-m-min {
    .tpl {
      .content {
        p { margin: 50px 0 100px; }
        .title { font-size: 256px; letter-spacing: -4px; }
      }
    }
  }

  @media @media-l-min {
    .tpl {
      .content .title { font-size: 400px; }
    }
  }
}