@navHeightMobile: 60px;
@navHeight: 80px;

.navbar {
  .fixedFull; height: @navHeightMobile; z-index: 1000; background-color: @colorDarktPetrol; border-bottom: 2px solid @colorLightPetrol; padding: 0; transition: 0.2s background-color ease-in-out, 0.2s height ease-in-out;
  &.nav-wrap.main { display: none; }
  &.nav-wrap.mobile {
    .navWrapper {
      display: flex; align-items: center; justify-content: space-between; min-width: 100%; height: @navHeightMobile; transition: 0.2s height ease-in-out; padding: 0 20px;
      .brand .navbar-brand { margin-right: 0; padding: 8px; }
      .navbar-toggler { position: absolute; right: 16px; border: none; color: @colorYellow; font-size: 16px; letter-spacing: 2px; font-weight: 600; padding: 8px 16px; z-index: 5000; }
    }
    .navbar-collapse {
      background-color: @colorLightPetrol;
      .navbar-nav {
        display: flex; align-items: center; justify-content: center; width: 100%; min-height: calc(100vh - 60px);
        .nav-item { flex: 0 1 auto; padding: 12px; }
        .nav-item .before { position: absolute; left: -8px; bottom: 24px; fill: transparent; transition: 0.25s fill ease-in-out; }
        .nav-item span { color: @colorLightWhite; font-family: @font-family-default; font-size: 16px; font-weight: 600; text-transform: lowercase; letter-spacing: 1px; }
        .nav-item:hover .before,
        .nav-item:focus .before,
        .nav-item.active .before { fill: @colorYellow; }
      }
    }
  }

  @media @media-l-min {
    &.nav-wrap.mobile { display: none; }
    &.nav-wrap.main {
      display: block; min-width: 100%; background-color: transparent; border: none; height: @navHeight;
      .navWrapper {
        display: flex; align-items: center; justify-content: space-between; height: @navHeight; transition: 0.2s height ease-in-out; padding: 0 20px;
        .navbar-collapse {
          margin-right: 40px;
          .navbar-nav {
            display: flex; align-items: center; justify-content: center; width: 100%; margin: 0;
            .nav-item { flex: 0 1 auto; padding: 8px; }
            .nav-item span { color: @colorLightWhite; font-family: @font-family-default; font-size: 14px; font-weight: 600; text-transform: lowercase; letter-spacing: 1px; }
            .nav-item .before { position: absolute; left: -8px; bottom: 24px; fill: transparent; transition: 0.25s fill ease-in-out; }
            .nav-item +.nav-item { margin-left: 32px; }
            .nav-item:hover .before,
            .nav-item:focus .before,
            .nav-item.active .before { fill: @colorYellow; }
          }
        }
      }
    }
  }
}