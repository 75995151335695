/* =====================================================================================================================
====================================================== PAGE STYLES =====================================================
===================================================================================================================== */
/* =====================================================================================================================
============================================ Gridx SYSTEM BY TOMÁŠ JORPALIDIS ===========================================
===================================================================================================================== */
/* =====================================================================================================================
===================================================== gridx SETTINGS ====================================================
===================================================================================================================== */
.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 5px;
  padding-right: 5px;
  width: 100%;
}
@media (min-width: 1680px) {
  .container {
    padding-left: 6px;
    padding-right: 6px;
  }
}
@media (min-width: 768px) {
  .container {
    width: 730px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 960px;
  }
}
@media (min-width: 1280px) {
  .container {
    width: 1036px;
  }
  .container.container-medium {
    width: 1200px;
  }
}
@media (min-width: 1440px) {
  .container {
    width: 1092px;
  }
  .container.container-medium {
    width: 1360px;
  }
}
@media (min-width: 1680px) {
  .container.container-medium {
    width: 1450px;
  }
}
@media (min-width: 1920px) {
  .container.container-medium {
    width: 1660px;
  }
}
.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 5px;
  padding-right: 5px;
}
@media (min-width: 1680px) {
  .container-fluid {
    padding-left: 6px;
    padding-right: 6px;
  }
}
.row {
  margin-left: -5px;
  margin-right: -5px;
}
@media (min-width: 1680px) {
  .row {
    margin-left: -6px;
    margin-right: -6px;
  }
}
.grid-xs-1,
.grid-s-1,
.grid-m-1,
.grid-l-1,
.grid-xl-1,
.grid-xxl-1,
.grid-xs-2,
.grid-s-2,
.grid-m-2,
.grid-l-2,
.grid-xl-2,
.grid-xxl-2,
.grid-xs-3,
.grid-s-3,
.grid-m-3,
.grid-l-3,
.grid-xl-3,
.grid-xxl-3,
.grid-xs-4,
.grid-s-4,
.grid-m-4,
.grid-l-4,
.grid-xl-4,
.grid-xxl-4,
.grid-xs-5,
.grid-s-5,
.grid-m-5,
.grid-l-5,
.grid-xl-5,
.grid-xxl-5,
.grid-xs-6,
.grid-s-6,
.grid-m-6,
.grid-l-6,
.grid-xl-6,
.grid-xxl-6,
.grid-xs-7,
.grid-s-7,
.grid-m-7,
.grid-l-7,
.grid-xl-7,
.grid-xxl-7,
.grid-xs-8,
.grid-s-8,
.grid-m-8,
.grid-l-8,
.grid-xl-8,
.grid-xxl-8,
.grid-xs-9,
.grid-s-9,
.grid-m-9,
.grid-l-9,
.grid-xl-9,
.grid-xxl-9,
.grid-xs-10,
.grid-s-10,
.grid-m-10,
.grid-l-10,
.grid-xl-10,
.grid-xxl-10,
.grid-xs-11,
.grid-s-11,
.grid-m-11,
.grid-l-11,
.grid-xl-11,
.grid-xxl-11,
.grid-xs-12,
.grid-s-12,
.grid-m-12,
.grid-l-12,
.grid-xl-12,
.grid-xxl-12,
.grid-xs-13,
.grid-s-13,
.grid-m-13,
.grid-l-13,
.grid-xl-13,
.grid-xxl-13,
.grid-xs-14,
.grid-s-14,
.grid-m-14,
.grid-l-14,
.grid-xl-14,
.grid-xxl-14,
.grid-xs-15,
.grid-s-15,
.grid-m-15,
.grid-l-15,
.grid-xl-15,
.grid-xxl-15,
.grid-xs-16,
.grid-s-16,
.grid-m-16,
.grid-l-16,
.grid-xl-16,
.grid-xxl-16,
.grid-xs-17,
.grid-s-17,
.grid-m-17,
.grid-l-17,
.grid-xl-17,
.grid-xxl-17,
.grid-xs-18,
.grid-s-18,
.grid-m-18,
.grid-l-18,
.grid-xl-18,
.grid-xxl-18,
.grid-xs-19,
.grid-s-19,
.grid-m-19,
.grid-l-19,
.grid-xl-19,
.grid-xxl-19,
.grid-xs-20,
.grid-s-20,
.grid-m-20,
.grid-l-20,
.grid-xl-20,
.grid-xxl-20,
.grid-xs-21,
.grid-s-21,
.grid-m-21,
.grid-l-21,
.grid-xl-21,
.grid-xxl-21,
.grid-xs-22,
.grid-s-22,
.grid-m-22,
.grid-l-22,
.grid-xl-22,
.grid-xxl-22,
.grid-xs-23,
.grid-s-23,
.grid-m-23,
.grid-l-23,
.grid-xl-23,
.grid-xxl-23,
.grid-xs-24,
.grid-s-24,
.grid-m-24,
.grid-l-24,
.grid-xl-24,
.grid-xxl-24 {
  position: relative;
  min-height: 1px;
  padding-left: 5px;
  padding-right: 5px;
}
@media (min-width: 1680px) {
  .grid-xs-1,
  .grid-s-1,
  .grid-m-1,
  .grid-l-1,
  .grid-xl-1,
  .grid-xxl-1,
  .grid-xs-2,
  .grid-s-2,
  .grid-m-2,
  .grid-l-2,
  .grid-xl-2,
  .grid-xxl-2,
  .grid-xs-3,
  .grid-s-3,
  .grid-m-3,
  .grid-l-3,
  .grid-xl-3,
  .grid-xxl-3,
  .grid-xs-4,
  .grid-s-4,
  .grid-m-4,
  .grid-l-4,
  .grid-xl-4,
  .grid-xxl-4,
  .grid-xs-5,
  .grid-s-5,
  .grid-m-5,
  .grid-l-5,
  .grid-xl-5,
  .grid-xxl-5,
  .grid-xs-6,
  .grid-s-6,
  .grid-m-6,
  .grid-l-6,
  .grid-xl-6,
  .grid-xxl-6,
  .grid-xs-7,
  .grid-s-7,
  .grid-m-7,
  .grid-l-7,
  .grid-xl-7,
  .grid-xxl-7,
  .grid-xs-8,
  .grid-s-8,
  .grid-m-8,
  .grid-l-8,
  .grid-xl-8,
  .grid-xxl-8,
  .grid-xs-9,
  .grid-s-9,
  .grid-m-9,
  .grid-l-9,
  .grid-xl-9,
  .grid-xxl-9,
  .grid-xs-10,
  .grid-s-10,
  .grid-m-10,
  .grid-l-10,
  .grid-xl-10,
  .grid-xxl-10,
  .grid-xs-11,
  .grid-s-11,
  .grid-m-11,
  .grid-l-11,
  .grid-xl-11,
  .grid-xxl-11,
  .grid-xs-12,
  .grid-s-12,
  .grid-m-12,
  .grid-l-12,
  .grid-xl-12,
  .grid-xxl-12,
  .grid-xs-13,
  .grid-s-13,
  .grid-m-13,
  .grid-l-13,
  .grid-xl-13,
  .grid-xxl-13,
  .grid-xs-14,
  .grid-s-14,
  .grid-m-14,
  .grid-l-14,
  .grid-xl-14,
  .grid-xxl-14,
  .grid-xs-15,
  .grid-s-15,
  .grid-m-15,
  .grid-l-15,
  .grid-xl-15,
  .grid-xxl-15,
  .grid-xs-16,
  .grid-s-16,
  .grid-m-16,
  .grid-l-16,
  .grid-xl-16,
  .grid-xxl-16,
  .grid-xs-17,
  .grid-s-17,
  .grid-m-17,
  .grid-l-17,
  .grid-xl-17,
  .grid-xxl-17,
  .grid-xs-18,
  .grid-s-18,
  .grid-m-18,
  .grid-l-18,
  .grid-xl-18,
  .grid-xxl-18,
  .grid-xs-19,
  .grid-s-19,
  .grid-m-19,
  .grid-l-19,
  .grid-xl-19,
  .grid-xxl-19,
  .grid-xs-20,
  .grid-s-20,
  .grid-m-20,
  .grid-l-20,
  .grid-xl-20,
  .grid-xxl-20,
  .grid-xs-21,
  .grid-s-21,
  .grid-m-21,
  .grid-l-21,
  .grid-xl-21,
  .grid-xxl-21,
  .grid-xs-22,
  .grid-s-22,
  .grid-m-22,
  .grid-l-22,
  .grid-xl-22,
  .grid-xxl-22,
  .grid-xs-23,
  .grid-s-23,
  .grid-m-23,
  .grid-l-23,
  .grid-xl-23,
  .grid-xxl-23,
  .grid-xs-24,
  .grid-s-24,
  .grid-m-24,
  .grid-l-24,
  .grid-xl-24,
  .grid-xxl-24 {
    padding-left: 6px;
    padding-right: 6px;
  }
}
.grid-xs-1,
.grid-xs-2,
.grid-xs-3,
.grid-xs-4,
.grid-xs-5,
.grid-xs-6,
.grid-xs-7,
.grid-xs-8,
.grid-xs-9,
.grid-xs-10,
.grid-xs-11,
.grid-xs-12,
.grid-xs-13,
.grid-xs-14,
.grid-xs-15,
.grid-xs-16,
.grid-xs-17,
.grid-xs-18,
.grid-xs-19,
.grid-xs-20,
.grid-xs-21,
.grid-xs-22,
.grid-xs-23,
.grid-xs-24 {
  float: left;
}
.grid-xs-24 {
  width: 100%;
}
.grid-xs-23 {
  width: 95.83333333%;
}
.grid-xs-22 {
  width: 91.66666667%;
}
.grid-xs-21 {
  width: 87.5%;
}
.grid-xs-20 {
  width: 83.33333333%;
}
.grid-xs-19 {
  width: 79.16666667%;
}
.grid-xs-18 {
  width: 75%;
}
.grid-xs-17 {
  width: 70.83333333%;
}
.grid-xs-16 {
  width: 66.66666667%;
}
.grid-xs-15 {
  width: 62.5%;
}
.grid-xs-14 {
  width: 58.33333333%;
}
.grid-xs-13 {
  width: 54.16666667%;
}
.grid-xs-12 {
  width: 50%;
}
.grid-xs-11 {
  width: 45.83333333%;
}
.grid-xs-10 {
  width: 41.66666667%;
}
.grid-xs-9 {
  width: 37.5%;
}
.grid-xs-8 {
  width: 33.33333333%;
}
.grid-xs-7 {
  width: 29.16666667%;
}
.grid-xs-6 {
  width: 25%;
}
.grid-xs-5 {
  width: 20.83333333%;
}
.grid-xs-4 {
  width: 16.66666667%;
}
.grid-xs-3 {
  width: 12.5%;
}
.grid-xs-2 {
  width: 8.33333333%;
}
.grid-xs-1 {
  width: 4.16666667%;
}
.grid-xs-pull-24 {
  right: 100%;
}
.grid-xs-pull-23 {
  right: 95.83333333%;
}
.grid-xs-pull-22 {
  right: 91.66666667%;
}
.grid-xs-pull-21 {
  right: 87.5%;
}
.grid-xs-pull-20 {
  right: 83.33333333%;
}
.grid-xs-pull-19 {
  right: 79.16666667%;
}
.grid-xs-pull-18 {
  right: 75%;
}
.grid-xs-pull-17 {
  right: 70.83333333%;
}
.grid-xs-pull-16 {
  right: 66.66666667%;
}
.grid-xs-pull-15 {
  right: 62.5%;
}
.grid-xs-pull-14 {
  right: 58.33333333%;
}
.grid-xs-pull-13 {
  right: 54.16666667%;
}
.grid-xs-pull-12 {
  right: 50%;
}
.grid-xs-pull-11 {
  right: 45.83333333%;
}
.grid-xs-pull-10 {
  right: 41.66666667%;
}
.grid-xs-pull-9 {
  right: 37.5%;
}
.grid-xs-pull-8 {
  right: 33.33333333%;
}
.grid-xs-pull-7 {
  right: 29.16666667%;
}
.grid-xs-pull-6 {
  right: 25%;
}
.grid-xs-pull-5 {
  right: 20.83333333%;
}
.grid-xs-pull-4 {
  right: 16.66666667%;
}
.grid-xs-pull-3 {
  right: 12.5%;
}
.grid-xs-pull-2 {
  right: 8.33333333%;
}
.grid-xs-pull-1 {
  right: 4.16666667%;
}
.grid-xs-pull-0 {
  right: auto;
}
.grid-xs-push-24 {
  left: 100%;
}
.grid-xs-push-23 {
  left: 95.83333333%;
}
.grid-xs-push-22 {
  left: 91.66666667%;
}
.grid-xs-push-21 {
  left: 87.5%;
}
.grid-xs-push-20 {
  left: 83.33333333%;
}
.grid-xs-push-19 {
  left: 79.16666667%;
}
.grid-xs-push-18 {
  left: 75%;
}
.grid-xs-push-17 {
  left: 70.83333333%;
}
.grid-xs-push-16 {
  left: 66.66666667%;
}
.grid-xs-push-15 {
  left: 62.5%;
}
.grid-xs-push-14 {
  left: 58.33333333%;
}
.grid-xs-push-13 {
  left: 54.16666667%;
}
.grid-xs-push-12 {
  left: 50%;
}
.grid-xs-push-11 {
  left: 45.83333333%;
}
.grid-xs-push-10 {
  left: 41.66666667%;
}
.grid-xs-push-9 {
  left: 37.5%;
}
.grid-xs-push-8 {
  left: 33.33333333%;
}
.grid-xs-push-7 {
  left: 29.16666667%;
}
.grid-xs-push-6 {
  left: 25%;
}
.grid-xs-push-5 {
  left: 20.83333333%;
}
.grid-xs-push-4 {
  left: 16.66666667%;
}
.grid-xs-push-3 {
  left: 12.5%;
}
.grid-xs-push-2 {
  left: 8.33333333%;
}
.grid-xs-push-1 {
  left: 4.16666667%;
}
.grid-xs-push-0 {
  left: auto;
}
.grid-xs-offset-24 {
  margin-left: 100%;
}
.grid-xs-offset-23 {
  margin-left: 95.83333333%;
}
.grid-xs-offset-22 {
  margin-left: 91.66666667%;
}
.grid-xs-offset-21 {
  margin-left: 87.5%;
}
.grid-xs-offset-20 {
  margin-left: 83.33333333%;
}
.grid-xs-offset-19 {
  margin-left: 79.16666667%;
}
.grid-xs-offset-18 {
  margin-left: 75%;
}
.grid-xs-offset-17 {
  margin-left: 70.83333333%;
}
.grid-xs-offset-16 {
  margin-left: 66.66666667%;
}
.grid-xs-offset-15 {
  margin-left: 62.5%;
}
.grid-xs-offset-14 {
  margin-left: 58.33333333%;
}
.grid-xs-offset-13 {
  margin-left: 54.16666667%;
}
.grid-xs-offset-12 {
  margin-left: 50%;
}
.grid-xs-offset-11 {
  margin-left: 45.83333333%;
}
.grid-xs-offset-10 {
  margin-left: 41.66666667%;
}
.grid-xs-offset-9 {
  margin-left: 37.5%;
}
.grid-xs-offset-8 {
  margin-left: 33.33333333%;
}
.grid-xs-offset-7 {
  margin-left: 29.16666667%;
}
.grid-xs-offset-6 {
  margin-left: 25%;
}
.grid-xs-offset-5 {
  margin-left: 20.83333333%;
}
.grid-xs-offset-4 {
  margin-left: 16.66666667%;
}
.grid-xs-offset-3 {
  margin-left: 12.5%;
}
.grid-xs-offset-2 {
  margin-left: 8.33333333%;
}
.grid-xs-offset-1 {
  margin-left: 4.16666667%;
}
.grid-xs-offset-0 {
  margin-left: 0%;
}
@media (min-width: 500px) {
  .grid-s-1,
  .grid-s-2,
  .grid-s-3,
  .grid-s-4,
  .grid-s-5,
  .grid-s-6,
  .grid-s-7,
  .grid-s-8,
  .grid-s-9,
  .grid-s-10,
  .grid-s-11,
  .grid-s-12,
  .grid-s-13,
  .grid-s-14,
  .grid-s-15,
  .grid-s-16,
  .grid-s-17,
  .grid-s-18,
  .grid-s-19,
  .grid-s-20,
  .grid-s-21,
  .grid-s-22,
  .grid-s-23,
  .grid-s-24 {
    float: left;
  }
  .grid-s-24 {
    width: 100%;
  }
  .grid-s-23 {
    width: 95.83333333%;
  }
  .grid-s-22 {
    width: 91.66666667%;
  }
  .grid-s-21 {
    width: 87.5%;
  }
  .grid-s-20 {
    width: 83.33333333%;
  }
  .grid-s-19 {
    width: 79.16666667%;
  }
  .grid-s-18 {
    width: 75%;
  }
  .grid-s-17 {
    width: 70.83333333%;
  }
  .grid-s-16 {
    width: 66.66666667%;
  }
  .grid-s-15 {
    width: 62.5%;
  }
  .grid-s-14 {
    width: 58.33333333%;
  }
  .grid-s-13 {
    width: 54.16666667%;
  }
  .grid-s-12 {
    width: 50%;
  }
  .grid-s-11 {
    width: 45.83333333%;
  }
  .grid-s-10 {
    width: 41.66666667%;
  }
  .grid-s-9 {
    width: 37.5%;
  }
  .grid-s-8 {
    width: 33.33333333%;
  }
  .grid-s-7 {
    width: 29.16666667%;
  }
  .grid-s-6 {
    width: 25%;
  }
  .grid-s-5 {
    width: 20.83333333%;
  }
  .grid-s-4 {
    width: 16.66666667%;
  }
  .grid-s-3 {
    width: 12.5%;
  }
  .grid-s-2 {
    width: 8.33333333%;
  }
  .grid-s-1 {
    width: 4.16666667%;
  }
  .grid-s-pull-24 {
    right: 100%;
  }
  .grid-s-pull-23 {
    right: 95.83333333%;
  }
  .grid-s-pull-22 {
    right: 91.66666667%;
  }
  .grid-s-pull-21 {
    right: 87.5%;
  }
  .grid-s-pull-20 {
    right: 83.33333333%;
  }
  .grid-s-pull-19 {
    right: 79.16666667%;
  }
  .grid-s-pull-18 {
    right: 75%;
  }
  .grid-s-pull-17 {
    right: 70.83333333%;
  }
  .grid-s-pull-16 {
    right: 66.66666667%;
  }
  .grid-s-pull-15 {
    right: 62.5%;
  }
  .grid-s-pull-14 {
    right: 58.33333333%;
  }
  .grid-s-pull-13 {
    right: 54.16666667%;
  }
  .grid-s-pull-12 {
    right: 50%;
  }
  .grid-s-pull-11 {
    right: 45.83333333%;
  }
  .grid-s-pull-10 {
    right: 41.66666667%;
  }
  .grid-s-pull-9 {
    right: 37.5%;
  }
  .grid-s-pull-8 {
    right: 33.33333333%;
  }
  .grid-s-pull-7 {
    right: 29.16666667%;
  }
  .grid-s-pull-6 {
    right: 25%;
  }
  .grid-s-pull-5 {
    right: 20.83333333%;
  }
  .grid-s-pull-4 {
    right: 16.66666667%;
  }
  .grid-s-pull-3 {
    right: 12.5%;
  }
  .grid-s-pull-2 {
    right: 8.33333333%;
  }
  .grid-s-pull-1 {
    right: 4.16666667%;
  }
  .grid-s-pull-0 {
    right: auto;
  }
  .grid-s-push-24 {
    left: 100%;
  }
  .grid-s-push-23 {
    left: 95.83333333%;
  }
  .grid-s-push-22 {
    left: 91.66666667%;
  }
  .grid-s-push-21 {
    left: 87.5%;
  }
  .grid-s-push-20 {
    left: 83.33333333%;
  }
  .grid-s-push-19 {
    left: 79.16666667%;
  }
  .grid-s-push-18 {
    left: 75%;
  }
  .grid-s-push-17 {
    left: 70.83333333%;
  }
  .grid-s-push-16 {
    left: 66.66666667%;
  }
  .grid-s-push-15 {
    left: 62.5%;
  }
  .grid-s-push-14 {
    left: 58.33333333%;
  }
  .grid-s-push-13 {
    left: 54.16666667%;
  }
  .grid-s-push-12 {
    left: 50%;
  }
  .grid-s-push-11 {
    left: 45.83333333%;
  }
  .grid-s-push-10 {
    left: 41.66666667%;
  }
  .grid-s-push-9 {
    left: 37.5%;
  }
  .grid-s-push-8 {
    left: 33.33333333%;
  }
  .grid-s-push-7 {
    left: 29.16666667%;
  }
  .grid-s-push-6 {
    left: 25%;
  }
  .grid-s-push-5 {
    left: 20.83333333%;
  }
  .grid-s-push-4 {
    left: 16.66666667%;
  }
  .grid-s-push-3 {
    left: 12.5%;
  }
  .grid-s-push-2 {
    left: 8.33333333%;
  }
  .grid-s-push-1 {
    left: 4.16666667%;
  }
  .grid-s-push-0 {
    left: auto;
  }
  .grid-s-offset-24 {
    margin-left: 100%;
  }
  .grid-s-offset-23 {
    margin-left: 95.83333333%;
  }
  .grid-s-offset-22 {
    margin-left: 91.66666667%;
  }
  .grid-s-offset-21 {
    margin-left: 87.5%;
  }
  .grid-s-offset-20 {
    margin-left: 83.33333333%;
  }
  .grid-s-offset-19 {
    margin-left: 79.16666667%;
  }
  .grid-s-offset-18 {
    margin-left: 75%;
  }
  .grid-s-offset-17 {
    margin-left: 70.83333333%;
  }
  .grid-s-offset-16 {
    margin-left: 66.66666667%;
  }
  .grid-s-offset-15 {
    margin-left: 62.5%;
  }
  .grid-s-offset-14 {
    margin-left: 58.33333333%;
  }
  .grid-s-offset-13 {
    margin-left: 54.16666667%;
  }
  .grid-s-offset-12 {
    margin-left: 50%;
  }
  .grid-s-offset-11 {
    margin-left: 45.83333333%;
  }
  .grid-s-offset-10 {
    margin-left: 41.66666667%;
  }
  .grid-s-offset-9 {
    margin-left: 37.5%;
  }
  .grid-s-offset-8 {
    margin-left: 33.33333333%;
  }
  .grid-s-offset-7 {
    margin-left: 29.16666667%;
  }
  .grid-s-offset-6 {
    margin-left: 25%;
  }
  .grid-s-offset-5 {
    margin-left: 20.83333333%;
  }
  .grid-s-offset-4 {
    margin-left: 16.66666667%;
  }
  .grid-s-offset-3 {
    margin-left: 12.5%;
  }
  .grid-s-offset-2 {
    margin-left: 8.33333333%;
  }
  .grid-s-offset-1 {
    margin-left: 4.16666667%;
  }
  .grid-s-offset-0 {
    margin-left: 0%;
  }
}
@media (min-width: 768px) {
  .grid-m-1,
  .grid-m-2,
  .grid-m-3,
  .grid-m-4,
  .grid-m-5,
  .grid-m-6,
  .grid-m-7,
  .grid-m-8,
  .grid-m-9,
  .grid-m-10,
  .grid-m-11,
  .grid-m-12,
  .grid-m-13,
  .grid-m-14,
  .grid-m-15,
  .grid-m-16,
  .grid-m-17,
  .grid-m-18,
  .grid-m-19,
  .grid-m-20,
  .grid-m-21,
  .grid-m-22,
  .grid-m-23,
  .grid-m-24 {
    float: left;
  }
  .grid-m-24 {
    width: 100%;
  }
  .grid-m-23 {
    width: 95.83333333%;
  }
  .grid-m-22 {
    width: 91.66666667%;
  }
  .grid-m-21 {
    width: 87.5%;
  }
  .grid-m-20 {
    width: 83.33333333%;
  }
  .grid-m-19 {
    width: 79.16666667%;
  }
  .grid-m-18 {
    width: 75%;
  }
  .grid-m-17 {
    width: 70.83333333%;
  }
  .grid-m-16 {
    width: 66.66666667%;
  }
  .grid-m-15 {
    width: 62.5%;
  }
  .grid-m-14 {
    width: 58.33333333%;
  }
  .grid-m-13 {
    width: 54.16666667%;
  }
  .grid-m-12 {
    width: 50%;
  }
  .grid-m-11 {
    width: 45.83333333%;
  }
  .grid-m-10 {
    width: 41.66666667%;
  }
  .grid-m-9 {
    width: 37.5%;
  }
  .grid-m-8 {
    width: 33.33333333%;
  }
  .grid-m-7 {
    width: 29.16666667%;
  }
  .grid-m-6 {
    width: 25%;
  }
  .grid-m-5 {
    width: 20.83333333%;
  }
  .grid-m-4 {
    width: 16.66666667%;
  }
  .grid-m-3 {
    width: 12.5%;
  }
  .grid-m-2 {
    width: 8.33333333%;
  }
  .grid-m-1 {
    width: 4.16666667%;
  }
  .grid-m-pull-24 {
    right: 100%;
  }
  .grid-m-pull-23 {
    right: 95.83333333%;
  }
  .grid-m-pull-22 {
    right: 91.66666667%;
  }
  .grid-m-pull-21 {
    right: 87.5%;
  }
  .grid-m-pull-20 {
    right: 83.33333333%;
  }
  .grid-m-pull-19 {
    right: 79.16666667%;
  }
  .grid-m-pull-18 {
    right: 75%;
  }
  .grid-m-pull-17 {
    right: 70.83333333%;
  }
  .grid-m-pull-16 {
    right: 66.66666667%;
  }
  .grid-m-pull-15 {
    right: 62.5%;
  }
  .grid-m-pull-14 {
    right: 58.33333333%;
  }
  .grid-m-pull-13 {
    right: 54.16666667%;
  }
  .grid-m-pull-12 {
    right: 50%;
  }
  .grid-m-pull-11 {
    right: 45.83333333%;
  }
  .grid-m-pull-10 {
    right: 41.66666667%;
  }
  .grid-m-pull-9 {
    right: 37.5%;
  }
  .grid-m-pull-8 {
    right: 33.33333333%;
  }
  .grid-m-pull-7 {
    right: 29.16666667%;
  }
  .grid-m-pull-6 {
    right: 25%;
  }
  .grid-m-pull-5 {
    right: 20.83333333%;
  }
  .grid-m-pull-4 {
    right: 16.66666667%;
  }
  .grid-m-pull-3 {
    right: 12.5%;
  }
  .grid-m-pull-2 {
    right: 8.33333333%;
  }
  .grid-m-pull-1 {
    right: 4.16666667%;
  }
  .grid-m-pull-0 {
    right: auto;
  }
  .grid-m-push-24 {
    left: 100%;
  }
  .grid-m-push-23 {
    left: 95.83333333%;
  }
  .grid-m-push-22 {
    left: 91.66666667%;
  }
  .grid-m-push-21 {
    left: 87.5%;
  }
  .grid-m-push-20 {
    left: 83.33333333%;
  }
  .grid-m-push-19 {
    left: 79.16666667%;
  }
  .grid-m-push-18 {
    left: 75%;
  }
  .grid-m-push-17 {
    left: 70.83333333%;
  }
  .grid-m-push-16 {
    left: 66.66666667%;
  }
  .grid-m-push-15 {
    left: 62.5%;
  }
  .grid-m-push-14 {
    left: 58.33333333%;
  }
  .grid-m-push-13 {
    left: 54.16666667%;
  }
  .grid-m-push-12 {
    left: 50%;
  }
  .grid-m-push-11 {
    left: 45.83333333%;
  }
  .grid-m-push-10 {
    left: 41.66666667%;
  }
  .grid-m-push-9 {
    left: 37.5%;
  }
  .grid-m-push-8 {
    left: 33.33333333%;
  }
  .grid-m-push-7 {
    left: 29.16666667%;
  }
  .grid-m-push-6 {
    left: 25%;
  }
  .grid-m-push-5 {
    left: 20.83333333%;
  }
  .grid-m-push-4 {
    left: 16.66666667%;
  }
  .grid-m-push-3 {
    left: 12.5%;
  }
  .grid-m-push-2 {
    left: 8.33333333%;
  }
  .grid-m-push-1 {
    left: 4.16666667%;
  }
  .grid-m-push-0 {
    left: auto;
  }
  .grid-m-offset-24 {
    margin-left: 100%;
  }
  .grid-m-offset-23 {
    margin-left: 95.83333333%;
  }
  .grid-m-offset-22 {
    margin-left: 91.66666667%;
  }
  .grid-m-offset-21 {
    margin-left: 87.5%;
  }
  .grid-m-offset-20 {
    margin-left: 83.33333333%;
  }
  .grid-m-offset-19 {
    margin-left: 79.16666667%;
  }
  .grid-m-offset-18 {
    margin-left: 75%;
  }
  .grid-m-offset-17 {
    margin-left: 70.83333333%;
  }
  .grid-m-offset-16 {
    margin-left: 66.66666667%;
  }
  .grid-m-offset-15 {
    margin-left: 62.5%;
  }
  .grid-m-offset-14 {
    margin-left: 58.33333333%;
  }
  .grid-m-offset-13 {
    margin-left: 54.16666667%;
  }
  .grid-m-offset-12 {
    margin-left: 50%;
  }
  .grid-m-offset-11 {
    margin-left: 45.83333333%;
  }
  .grid-m-offset-10 {
    margin-left: 41.66666667%;
  }
  .grid-m-offset-9 {
    margin-left: 37.5%;
  }
  .grid-m-offset-8 {
    margin-left: 33.33333333%;
  }
  .grid-m-offset-7 {
    margin-left: 29.16666667%;
  }
  .grid-m-offset-6 {
    margin-left: 25%;
  }
  .grid-m-offset-5 {
    margin-left: 20.83333333%;
  }
  .grid-m-offset-4 {
    margin-left: 16.66666667%;
  }
  .grid-m-offset-3 {
    margin-left: 12.5%;
  }
  .grid-m-offset-2 {
    margin-left: 8.33333333%;
  }
  .grid-m-offset-1 {
    margin-left: 4.16666667%;
  }
  .grid-m-offset-0 {
    margin-left: 0%;
  }
}
@media (min-width: 992px) {
  .grid-l-1,
  .grid-l-2,
  .grid-l-3,
  .grid-l-4,
  .grid-l-5,
  .grid-l-6,
  .grid-l-7,
  .grid-l-8,
  .grid-l-9,
  .grid-l-10,
  .grid-l-11,
  .grid-l-12,
  .grid-l-13,
  .grid-l-14,
  .grid-l-15,
  .grid-l-16,
  .grid-l-17,
  .grid-l-18,
  .grid-l-19,
  .grid-l-20,
  .grid-l-21,
  .grid-l-22,
  .grid-l-23,
  .grid-l-24 {
    float: left;
  }
  .grid-l-24 {
    width: 100%;
  }
  .grid-l-23 {
    width: 95.83333333%;
  }
  .grid-l-22 {
    width: 91.66666667%;
  }
  .grid-l-21 {
    width: 87.5%;
  }
  .grid-l-20 {
    width: 83.33333333%;
  }
  .grid-l-19 {
    width: 79.16666667%;
  }
  .grid-l-18 {
    width: 75%;
  }
  .grid-l-17 {
    width: 70.83333333%;
  }
  .grid-l-16 {
    width: 66.66666667%;
  }
  .grid-l-15 {
    width: 62.5%;
  }
  .grid-l-14 {
    width: 58.33333333%;
  }
  .grid-l-13 {
    width: 54.16666667%;
  }
  .grid-l-12 {
    width: 50%;
  }
  .grid-l-11 {
    width: 45.83333333%;
  }
  .grid-l-10 {
    width: 41.66666667%;
  }
  .grid-l-9 {
    width: 37.5%;
  }
  .grid-l-8 {
    width: 33.33333333%;
  }
  .grid-l-7 {
    width: 29.16666667%;
  }
  .grid-l-6 {
    width: 25%;
  }
  .grid-l-5 {
    width: 20.83333333%;
  }
  .grid-l-4 {
    width: 16.66666667%;
  }
  .grid-l-3 {
    width: 12.5%;
  }
  .grid-l-2 {
    width: 8.33333333%;
  }
  .grid-l-1 {
    width: 4.16666667%;
  }
  .grid-l-pull-24 {
    right: 100%;
  }
  .grid-l-pull-23 {
    right: 95.83333333%;
  }
  .grid-l-pull-22 {
    right: 91.66666667%;
  }
  .grid-l-pull-21 {
    right: 87.5%;
  }
  .grid-l-pull-20 {
    right: 83.33333333%;
  }
  .grid-l-pull-19 {
    right: 79.16666667%;
  }
  .grid-l-pull-18 {
    right: 75%;
  }
  .grid-l-pull-17 {
    right: 70.83333333%;
  }
  .grid-l-pull-16 {
    right: 66.66666667%;
  }
  .grid-l-pull-15 {
    right: 62.5%;
  }
  .grid-l-pull-14 {
    right: 58.33333333%;
  }
  .grid-l-pull-13 {
    right: 54.16666667%;
  }
  .grid-l-pull-12 {
    right: 50%;
  }
  .grid-l-pull-11 {
    right: 45.83333333%;
  }
  .grid-l-pull-10 {
    right: 41.66666667%;
  }
  .grid-l-pull-9 {
    right: 37.5%;
  }
  .grid-l-pull-8 {
    right: 33.33333333%;
  }
  .grid-l-pull-7 {
    right: 29.16666667%;
  }
  .grid-l-pull-6 {
    right: 25%;
  }
  .grid-l-pull-5 {
    right: 20.83333333%;
  }
  .grid-l-pull-4 {
    right: 16.66666667%;
  }
  .grid-l-pull-3 {
    right: 12.5%;
  }
  .grid-l-pull-2 {
    right: 8.33333333%;
  }
  .grid-l-pull-1 {
    right: 4.16666667%;
  }
  .grid-l-pull-0 {
    right: auto;
  }
  .grid-l-push-24 {
    left: 100%;
  }
  .grid-l-push-23 {
    left: 95.83333333%;
  }
  .grid-l-push-22 {
    left: 91.66666667%;
  }
  .grid-l-push-21 {
    left: 87.5%;
  }
  .grid-l-push-20 {
    left: 83.33333333%;
  }
  .grid-l-push-19 {
    left: 79.16666667%;
  }
  .grid-l-push-18 {
    left: 75%;
  }
  .grid-l-push-17 {
    left: 70.83333333%;
  }
  .grid-l-push-16 {
    left: 66.66666667%;
  }
  .grid-l-push-15 {
    left: 62.5%;
  }
  .grid-l-push-14 {
    left: 58.33333333%;
  }
  .grid-l-push-13 {
    left: 54.16666667%;
  }
  .grid-l-push-12 {
    left: 50%;
  }
  .grid-l-push-11 {
    left: 45.83333333%;
  }
  .grid-l-push-10 {
    left: 41.66666667%;
  }
  .grid-l-push-9 {
    left: 37.5%;
  }
  .grid-l-push-8 {
    left: 33.33333333%;
  }
  .grid-l-push-7 {
    left: 29.16666667%;
  }
  .grid-l-push-6 {
    left: 25%;
  }
  .grid-l-push-5 {
    left: 20.83333333%;
  }
  .grid-l-push-4 {
    left: 16.66666667%;
  }
  .grid-l-push-3 {
    left: 12.5%;
  }
  .grid-l-push-2 {
    left: 8.33333333%;
  }
  .grid-l-push-1 {
    left: 4.16666667%;
  }
  .grid-l-push-0 {
    left: auto;
  }
  .grid-l-offset-24 {
    margin-left: 100%;
  }
  .grid-l-offset-23 {
    margin-left: 95.83333333%;
  }
  .grid-l-offset-22 {
    margin-left: 91.66666667%;
  }
  .grid-l-offset-21 {
    margin-left: 87.5%;
  }
  .grid-l-offset-20 {
    margin-left: 83.33333333%;
  }
  .grid-l-offset-19 {
    margin-left: 79.16666667%;
  }
  .grid-l-offset-18 {
    margin-left: 75%;
  }
  .grid-l-offset-17 {
    margin-left: 70.83333333%;
  }
  .grid-l-offset-16 {
    margin-left: 66.66666667%;
  }
  .grid-l-offset-15 {
    margin-left: 62.5%;
  }
  .grid-l-offset-14 {
    margin-left: 58.33333333%;
  }
  .grid-l-offset-13 {
    margin-left: 54.16666667%;
  }
  .grid-l-offset-12 {
    margin-left: 50%;
  }
  .grid-l-offset-11 {
    margin-left: 45.83333333%;
  }
  .grid-l-offset-10 {
    margin-left: 41.66666667%;
  }
  .grid-l-offset-9 {
    margin-left: 37.5%;
  }
  .grid-l-offset-8 {
    margin-left: 33.33333333%;
  }
  .grid-l-offset-7 {
    margin-left: 29.16666667%;
  }
  .grid-l-offset-6 {
    margin-left: 25%;
  }
  .grid-l-offset-5 {
    margin-left: 20.83333333%;
  }
  .grid-l-offset-4 {
    margin-left: 16.66666667%;
  }
  .grid-l-offset-3 {
    margin-left: 12.5%;
  }
  .grid-l-offset-2 {
    margin-left: 8.33333333%;
  }
  .grid-l-offset-1 {
    margin-left: 4.16666667%;
  }
  .grid-l-offset-0 {
    margin-left: 0%;
  }
}
@media (min-width: 1200px) {
  .grid-xl-1,
  .grid-xl-2,
  .grid-xl-3,
  .grid-xl-4,
  .grid-xl-5,
  .grid-xl-6,
  .grid-xl-7,
  .grid-xl-8,
  .grid-xl-9,
  .grid-xl-10,
  .grid-xl-11,
  .grid-xl-12,
  .grid-xl-13,
  .grid-xl-14,
  .grid-xl-15,
  .grid-xl-16,
  .grid-xl-17,
  .grid-xl-18,
  .grid-xl-19,
  .grid-xl-20,
  .grid-xl-21,
  .grid-xl-22,
  .grid-xl-23,
  .grid-xl-24 {
    float: left;
  }
  .grid-xl-24 {
    width: 100%;
  }
  .grid-xl-23 {
    width: 95.83333333%;
  }
  .grid-xl-22 {
    width: 91.66666667%;
  }
  .grid-xl-21 {
    width: 87.5%;
  }
  .grid-xl-20 {
    width: 83.33333333%;
  }
  .grid-xl-19 {
    width: 79.16666667%;
  }
  .grid-xl-18 {
    width: 75%;
  }
  .grid-xl-17 {
    width: 70.83333333%;
  }
  .grid-xl-16 {
    width: 66.66666667%;
  }
  .grid-xl-15 {
    width: 62.5%;
  }
  .grid-xl-14 {
    width: 58.33333333%;
  }
  .grid-xl-13 {
    width: 54.16666667%;
  }
  .grid-xl-12 {
    width: 50%;
  }
  .grid-xl-11 {
    width: 45.83333333%;
  }
  .grid-xl-10 {
    width: 41.66666667%;
  }
  .grid-xl-9 {
    width: 37.5%;
  }
  .grid-xl-8 {
    width: 33.33333333%;
  }
  .grid-xl-7 {
    width: 29.16666667%;
  }
  .grid-xl-6 {
    width: 25%;
  }
  .grid-xl-5 {
    width: 20.83333333%;
  }
  .grid-xl-4 {
    width: 16.66666667%;
  }
  .grid-xl-3 {
    width: 12.5%;
  }
  .grid-xl-2 {
    width: 8.33333333%;
  }
  .grid-xl-1 {
    width: 4.16666667%;
  }
  .grid-xl-pull-24 {
    right: 100%;
  }
  .grid-xl-pull-23 {
    right: 95.83333333%;
  }
  .grid-xl-pull-22 {
    right: 91.66666667%;
  }
  .grid-xl-pull-21 {
    right: 87.5%;
  }
  .grid-xl-pull-20 {
    right: 83.33333333%;
  }
  .grid-xl-pull-19 {
    right: 79.16666667%;
  }
  .grid-xl-pull-18 {
    right: 75%;
  }
  .grid-xl-pull-17 {
    right: 70.83333333%;
  }
  .grid-xl-pull-16 {
    right: 66.66666667%;
  }
  .grid-xl-pull-15 {
    right: 62.5%;
  }
  .grid-xl-pull-14 {
    right: 58.33333333%;
  }
  .grid-xl-pull-13 {
    right: 54.16666667%;
  }
  .grid-xl-pull-12 {
    right: 50%;
  }
  .grid-xl-pull-11 {
    right: 45.83333333%;
  }
  .grid-xl-pull-10 {
    right: 41.66666667%;
  }
  .grid-xl-pull-9 {
    right: 37.5%;
  }
  .grid-xl-pull-8 {
    right: 33.33333333%;
  }
  .grid-xl-pull-7 {
    right: 29.16666667%;
  }
  .grid-xl-pull-6 {
    right: 25%;
  }
  .grid-xl-pull-5 {
    right: 20.83333333%;
  }
  .grid-xl-pull-4 {
    right: 16.66666667%;
  }
  .grid-xl-pull-3 {
    right: 12.5%;
  }
  .grid-xl-pull-2 {
    right: 8.33333333%;
  }
  .grid-xl-pull-1 {
    right: 4.16666667%;
  }
  .grid-xl-pull-0 {
    right: auto;
  }
  .grid-xl-push-24 {
    left: 100%;
  }
  .grid-xl-push-23 {
    left: 95.83333333%;
  }
  .grid-xl-push-22 {
    left: 91.66666667%;
  }
  .grid-xl-push-21 {
    left: 87.5%;
  }
  .grid-xl-push-20 {
    left: 83.33333333%;
  }
  .grid-xl-push-19 {
    left: 79.16666667%;
  }
  .grid-xl-push-18 {
    left: 75%;
  }
  .grid-xl-push-17 {
    left: 70.83333333%;
  }
  .grid-xl-push-16 {
    left: 66.66666667%;
  }
  .grid-xl-push-15 {
    left: 62.5%;
  }
  .grid-xl-push-14 {
    left: 58.33333333%;
  }
  .grid-xl-push-13 {
    left: 54.16666667%;
  }
  .grid-xl-push-12 {
    left: 50%;
  }
  .grid-xl-push-11 {
    left: 45.83333333%;
  }
  .grid-xl-push-10 {
    left: 41.66666667%;
  }
  .grid-xl-push-9 {
    left: 37.5%;
  }
  .grid-xl-push-8 {
    left: 33.33333333%;
  }
  .grid-xl-push-7 {
    left: 29.16666667%;
  }
  .grid-xl-push-6 {
    left: 25%;
  }
  .grid-xl-push-5 {
    left: 20.83333333%;
  }
  .grid-xl-push-4 {
    left: 16.66666667%;
  }
  .grid-xl-push-3 {
    left: 12.5%;
  }
  .grid-xl-push-2 {
    left: 8.33333333%;
  }
  .grid-xl-push-1 {
    left: 4.16666667%;
  }
  .grid-xl-push-0 {
    left: auto;
  }
  .grid-xl-offset-24 {
    margin-left: 100%;
  }
  .grid-xl-offset-23 {
    margin-left: 95.83333333%;
  }
  .grid-xl-offset-22 {
    margin-left: 91.66666667%;
  }
  .grid-xl-offset-21 {
    margin-left: 87.5%;
  }
  .grid-xl-offset-20 {
    margin-left: 83.33333333%;
  }
  .grid-xl-offset-19 {
    margin-left: 79.16666667%;
  }
  .grid-xl-offset-18 {
    margin-left: 75%;
  }
  .grid-xl-offset-17 {
    margin-left: 70.83333333%;
  }
  .grid-xl-offset-16 {
    margin-left: 66.66666667%;
  }
  .grid-xl-offset-15 {
    margin-left: 62.5%;
  }
  .grid-xl-offset-14 {
    margin-left: 58.33333333%;
  }
  .grid-xl-offset-13 {
    margin-left: 54.16666667%;
  }
  .grid-xl-offset-12 {
    margin-left: 50%;
  }
  .grid-xl-offset-11 {
    margin-left: 45.83333333%;
  }
  .grid-xl-offset-10 {
    margin-left: 41.66666667%;
  }
  .grid-xl-offset-9 {
    margin-left: 37.5%;
  }
  .grid-xl-offset-8 {
    margin-left: 33.33333333%;
  }
  .grid-xl-offset-7 {
    margin-left: 29.16666667%;
  }
  .grid-xl-offset-6 {
    margin-left: 25%;
  }
  .grid-xl-offset-5 {
    margin-left: 20.83333333%;
  }
  .grid-xl-offset-4 {
    margin-left: 16.66666667%;
  }
  .grid-xl-offset-3 {
    margin-left: 12.5%;
  }
  .grid-xl-offset-2 {
    margin-left: 8.33333333%;
  }
  .grid-xl-offset-1 {
    margin-left: 4.16666667%;
  }
  .grid-xl-offset-0 {
    margin-left: 0%;
  }
}
@media (min-width: 1400px) {
  .grid-xxl-1,
  .grid-xxl-2,
  .grid-xxl-3,
  .grid-xxl-4,
  .grid-xxl-5,
  .grid-xxl-6,
  .grid-xxl-7,
  .grid-xxl-8,
  .grid-xxl-9,
  .grid-xxl-10,
  .grid-xxl-11,
  .grid-xxl-12,
  .grid-xxl-13,
  .grid-xxl-14,
  .grid-xxl-15,
  .grid-xxl-16,
  .grid-xxl-17,
  .grid-xxl-18,
  .grid-xxl-19,
  .grid-xxl-20,
  .grid-xxl-21,
  .grid-xxl-22,
  .grid-xxl-23,
  .grid-xxl-24 {
    float: left;
  }
  .grid-xxl-24 {
    width: 100%;
  }
  .grid-xxl-23 {
    width: 95.83333333%;
  }
  .grid-xxl-22 {
    width: 91.66666667%;
  }
  .grid-xxl-21 {
    width: 87.5%;
  }
  .grid-xxl-20 {
    width: 83.33333333%;
  }
  .grid-xxl-19 {
    width: 79.16666667%;
  }
  .grid-xxl-18 {
    width: 75%;
  }
  .grid-xxl-17 {
    width: 70.83333333%;
  }
  .grid-xxl-16 {
    width: 66.66666667%;
  }
  .grid-xxl-15 {
    width: 62.5%;
  }
  .grid-xxl-14 {
    width: 58.33333333%;
  }
  .grid-xxl-13 {
    width: 54.16666667%;
  }
  .grid-xxl-12 {
    width: 50%;
  }
  .grid-xxl-11 {
    width: 45.83333333%;
  }
  .grid-xxl-10 {
    width: 41.66666667%;
  }
  .grid-xxl-9 {
    width: 37.5%;
  }
  .grid-xxl-8 {
    width: 33.33333333%;
  }
  .grid-xxl-7 {
    width: 29.16666667%;
  }
  .grid-xxl-6 {
    width: 25%;
  }
  .grid-xxl-5 {
    width: 20.83333333%;
  }
  .grid-xxl-4 {
    width: 16.66666667%;
  }
  .grid-xxl-3 {
    width: 12.5%;
  }
  .grid-xxl-2 {
    width: 8.33333333%;
  }
  .grid-xxl-1 {
    width: 4.16666667%;
  }
  .grid-xxl-pull-24 {
    right: 100%;
  }
  .grid-xxl-pull-23 {
    right: 95.83333333%;
  }
  .grid-xxl-pull-22 {
    right: 91.66666667%;
  }
  .grid-xxl-pull-21 {
    right: 87.5%;
  }
  .grid-xxl-pull-20 {
    right: 83.33333333%;
  }
  .grid-xxl-pull-19 {
    right: 79.16666667%;
  }
  .grid-xxl-pull-18 {
    right: 75%;
  }
  .grid-xxl-pull-17 {
    right: 70.83333333%;
  }
  .grid-xxl-pull-16 {
    right: 66.66666667%;
  }
  .grid-xxl-pull-15 {
    right: 62.5%;
  }
  .grid-xxl-pull-14 {
    right: 58.33333333%;
  }
  .grid-xxl-pull-13 {
    right: 54.16666667%;
  }
  .grid-xxl-pull-12 {
    right: 50%;
  }
  .grid-xxl-pull-11 {
    right: 45.83333333%;
  }
  .grid-xxl-pull-10 {
    right: 41.66666667%;
  }
  .grid-xxl-pull-9 {
    right: 37.5%;
  }
  .grid-xxl-pull-8 {
    right: 33.33333333%;
  }
  .grid-xxl-pull-7 {
    right: 29.16666667%;
  }
  .grid-xxl-pull-6 {
    right: 25%;
  }
  .grid-xxl-pull-5 {
    right: 20.83333333%;
  }
  .grid-xxl-pull-4 {
    right: 16.66666667%;
  }
  .grid-xxl-pull-3 {
    right: 12.5%;
  }
  .grid-xxl-pull-2 {
    right: 8.33333333%;
  }
  .grid-xxl-pull-1 {
    right: 4.16666667%;
  }
  .grid-xxl-pull-0 {
    right: auto;
  }
  .grid-xxl-push-24 {
    left: 100%;
  }
  .grid-xxl-push-23 {
    left: 95.83333333%;
  }
  .grid-xxl-push-22 {
    left: 91.66666667%;
  }
  .grid-xxl-push-21 {
    left: 87.5%;
  }
  .grid-xxl-push-20 {
    left: 83.33333333%;
  }
  .grid-xxl-push-19 {
    left: 79.16666667%;
  }
  .grid-xxl-push-18 {
    left: 75%;
  }
  .grid-xxl-push-17 {
    left: 70.83333333%;
  }
  .grid-xxl-push-16 {
    left: 66.66666667%;
  }
  .grid-xxl-push-15 {
    left: 62.5%;
  }
  .grid-xxl-push-14 {
    left: 58.33333333%;
  }
  .grid-xxl-push-13 {
    left: 54.16666667%;
  }
  .grid-xxl-push-12 {
    left: 50%;
  }
  .grid-xxl-push-11 {
    left: 45.83333333%;
  }
  .grid-xxl-push-10 {
    left: 41.66666667%;
  }
  .grid-xxl-push-9 {
    left: 37.5%;
  }
  .grid-xxl-push-8 {
    left: 33.33333333%;
  }
  .grid-xxl-push-7 {
    left: 29.16666667%;
  }
  .grid-xxl-push-6 {
    left: 25%;
  }
  .grid-xxl-push-5 {
    left: 20.83333333%;
  }
  .grid-xxl-push-4 {
    left: 16.66666667%;
  }
  .grid-xxl-push-3 {
    left: 12.5%;
  }
  .grid-xxl-push-2 {
    left: 8.33333333%;
  }
  .grid-xxl-push-1 {
    left: 4.16666667%;
  }
  .grid-xxl-push-0 {
    left: auto;
  }
  .grid-xxl-offset-24 {
    margin-left: 100%;
  }
  .grid-xxl-offset-23 {
    margin-left: 95.83333333%;
  }
  .grid-xxl-offset-22 {
    margin-left: 91.66666667%;
  }
  .grid-xxl-offset-21 {
    margin-left: 87.5%;
  }
  .grid-xxl-offset-20 {
    margin-left: 83.33333333%;
  }
  .grid-xxl-offset-19 {
    margin-left: 79.16666667%;
  }
  .grid-xxl-offset-18 {
    margin-left: 75%;
  }
  .grid-xxl-offset-17 {
    margin-left: 70.83333333%;
  }
  .grid-xxl-offset-16 {
    margin-left: 66.66666667%;
  }
  .grid-xxl-offset-15 {
    margin-left: 62.5%;
  }
  .grid-xxl-offset-14 {
    margin-left: 58.33333333%;
  }
  .grid-xxl-offset-13 {
    margin-left: 54.16666667%;
  }
  .grid-xxl-offset-12 {
    margin-left: 50%;
  }
  .grid-xxl-offset-11 {
    margin-left: 45.83333333%;
  }
  .grid-xxl-offset-10 {
    margin-left: 41.66666667%;
  }
  .grid-xxl-offset-9 {
    margin-left: 37.5%;
  }
  .grid-xxl-offset-8 {
    margin-left: 33.33333333%;
  }
  .grid-xxl-offset-7 {
    margin-left: 29.16666667%;
  }
  .grid-xxl-offset-6 {
    margin-left: 25%;
  }
  .grid-xxl-offset-5 {
    margin-left: 20.83333333%;
  }
  .grid-xxl-offset-4 {
    margin-left: 16.66666667%;
  }
  .grid-xxl-offset-3 {
    margin-left: 12.5%;
  }
  .grid-xxl-offset-2 {
    margin-left: 8.33333333%;
  }
  .grid-xxl-offset-1 {
    margin-left: 4.16666667%;
  }
  .grid-xxl-offset-0 {
    margin-left: 0%;
  }
}
/* =====================================================================================================================
================================================== MIXINS & FUNCTIONS ==================================================
===================================================================================================================== */
.tpl.tpl--gray .row.row-flex {
  flex-direction: column-reverse;
}
@media (min-width: 992px) {
  .tpl.tpl--gray .row.row-flex {
    flex-direction: row;
  }
}
.row-flex {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
}
/* =====================================================================================================================
====================================================== DEBUG MODE ======================================================
===================================================================================================================== */
#tracy-debug:after {
  -webkit-user-drag: element;
  content: "XS";
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999;
  background: #cc0000;
  padding: 5px 15px;
  text-align: center;
  font-weight: bold;
  color: #fff;
  pointer-events: none;
}
@media (min-width: 500px) {
  #tracy-debug:after {
    content: "S";
  }
}
@media (min-width: 768px) {
  #tracy-debug:after {
    content: "M";
  }
}
@media (min-width: 992px) {
  #tracy-debug:after {
    content: "L";
  }
}
@media (min-width: 1200px) {
  #tracy-debug:after {
    content: "XL";
  }
}
@media (min-width: 1400px) {
  #tracy-debug:after {
    content: "XXL";
  }
}
#tracy-debug:after:hover {
  opacity: 0.1;
}
html {
  scroll-behavior: smooth;
  --scrollbarBG: #1C1D21;
  --thumbBG: #FFDE22;
}
html,
body {
  line-height: 26px;
  font-family: "Montserrat", sans-serif, sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.3px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  padding: 0!important;
}
html.overflow,
body.overflow {
  overflow: unset;
  height: 100%;
}
body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  position: relative;
  margin: 0;
  overflow: overlay;
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
body::-webkit-scrollbar {
  width: 11px;
}
body::-webkit-scrollbar-track {
  display: none;
}
body::-webkit-scrollbar-thumb {
  border-radius: 30px 0 0 30px;
  border-bottom: 12px solid transparent;
  border-top: 12px solid transparent;
  border-right: 100px solid #FFDE22;
}
body *:focus {
  outline: none;
}
* {
  box-sizing: border-box;
  margin: 0;
}
.h100p {
  height: 100%;
}
div,
section,
article,
header,
figure,
nav,
footer,
label,
main,
button,
aside,
form {
  position: relative;
}
img {
  max-width: 100%;
}
a {
  position: relative;
  text-decoration: none;
  transition: color 0.25s ease-out;
  font-weight: 400;
}
a:hover,
a:focus {
  text-decoration: none;
}
p {
  color: #F9F9F9;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 1.5;
  margin: 5px auto;
  text-align: justify;
  hyphens: auto;
}
h1,
h2,
h3,
h4,
h5 {
  font-family: "Montserrat", sans-serif, sans-serif;
}
h1 {
  font-size: 60px;
}
h2 {
  font-size: 48px;
}
h3 {
  font-size: 36px;
}
h4 {
  font-size: 30px;
}
h5 {
  font-size: 24px;
}
.CookieConsent {
  background-color: #1C1D21;
  padding: 16px;
}
.CookieConsent .content {
  color: #F9F9F9;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 1.5;
  margin: 0;
}
@media (min-width: 992px) {
  html,
  body {
    font-size: 20px;
    line-height: 32px;
  }
  h1 {
    font-size: 72px;
  }
  h2 {
    font-size: 60px;
  }
  h3 {
    font-size: 48px;
  }
  h4 {
    font-size: 36px;
  }
  h5 {
    font-size: 30px;
  }
  p {
    font-size: 20px;
  }
  .CookieConsent .content {
    font-size: 20px;
  }
}
.page-wrap {
  flex: 1 0 auto;
  overflow: hidden;
  padding-top: 0;
  width: 100%;
  background-color: inherit;
  animation: pageWrap 1s ease-in;
}
body.overlay {
  transition: all 2s ease-in-out;
  overflow: hidden;
}
#nette-debug:after {
  -webkit-user-drag: element;
  content: "XS";
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999;
  background: #cc0000;
  padding: 5px 15px;
  text-align: center;
  font-weight: bold;
  color: #fff;
  pointer-events: none;
}
@media (min-width: 500px) {
  #nette-debug:after {
    content: "S";
  }
}
@media (min-width: 768px) {
  #nette-debug:after {
    content: "M";
  }
}
@media (min-width: 992px) {
  #nette-debug:after {
    content: "L";
  }
}
@media (min-width: 1200px) {
  #nette-debug:after {
    content: "XL";
  }
}
@media (min-width: 1400px) {
  #nette-debug:after {
    content: "XXL";
  }
}
#nette-debug:after:hover {
  opacity: 0.1;
}
::selection {
  background: #2D545E;
  color: #F9F9F9;
}
/* =====================================================================================================================
=================================================== MEDIUM CONTAINER ===================================================
===================================================================================================================== */
.container-medium {
  margin-right: auto;
  margin-left: auto;
  padding-left: 5px;
  padding-right: 5px;
  max-width: 100%;
}
@media (min-width: 1680px) {
  .container-medium {
    padding-left: 6px;
    padding-right: 6px;
  }
}
@media (max-width: 1399px) {
  .container-medium {
    width: auto;
    margin-left: 50px;
    margin-right: 50px;
  }
}
@media (max-width: 767px) {
  .container-medium {
    margin-left: 10px;
    margin-right: 10px;
  }
}
@media (min-width: 1400px) {
  .container-medium {
    width: 1270px;
  }
}
@media (min-width: 1200px) {
  .container-fluid {
    width: 100%;
  }
}
.btn {
  border: 0;
  outline: 0;
  display: inline-block;
  font-size: 14px;
  letter-spacing: 2px;
  border-radius: 16px;
  padding: 20px 38px;
  background-color: #2D545E;
  text-shadow: 1px 1px 0 #386975;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: #F9F9F9;
  box-shadow: -5px -5px 20px #325e69, 5px 5px 20px #223f47;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  text-transform: uppercase;
}
.btn.btn--lightPetrol {
  background-color: #2D545E;
  text-shadow: 1px 1px 0 #386975;
  font-family: "Montserrat", sans-serif;
  color: #F9F9F9;
  box-shadow: -5px -5px 20px #325e69, 5px 5px 20px #223f47;
}
.btn.btn--lightPetrol:hover {
  box-shadow: -2px -2px 5px #325e69, 2px 2px 5px #223f47;
  color: #FFDE22;
}
.btn.btn--lightPetrol:active {
  box-shadow: inset 1px 1px 2px #223f47, inset -1px -1px 2px #325e69;
}
.btn.btn--lightBlack {
  background-color: #1C1D21;
  text-shadow: 1px 1px 0 #386975;
  font-family: "Montserrat", sans-serif;
  color: #F9F9F9;
  box-shadow: -5px -5px 15px #33353d, 5px 5px 15px #000;
}
.btn.btn--lightBlack:hover {
  box-shadow: -2px -2px 5px #33353d, 2px 2px 5px #000;
  color: #FFDE22;
}
.btn.btn--lightBlack:active {
  box-shadow: inset 1px 1px 2px #000, inset -1px -1px 2px #33353d;
}
.btn.btn--lightWhite {
  background-color: #f0f0f0;
  text-shadow: 1px 1px 0 #c6c6c6;
  font-family: "Montserrat", sans-serif;
  color: #c6c6c6;
  box-shadow: -5px -5px 20px #fff, 5px 5px 20px #c6c6c6;
}
.btn.btn--lightWhite svg {
  fill: #2D545E;
}
.btn.btn--lightWhite:hover {
  box-shadow: -2px -2px 5px #fff, 2px 2px 5px #c6c6c6;
  color: #FFDE22;
}
.btn.btn--lightWhite:hover svg {
  fill: #FFDE22;
}
.btn.btn--lightWhite:active {
  box-shadow: inset 1px 1px 2px #c6c6c6, inset -1px -1px 2px #fff;
}
.btn.btn--cookie {
  padding: 16px;
  color: #FFDE22;
  margin: 0 16px;
}
.btn.btn--decline {
  color: #F9F9F9;
  font-size: 10px;
}
@media (min-width: 992px) {
  .btn {
    font-size: 16px;
    padding: 32px 64px;
  }
}
/* LIBS */
@keyframes animation-line {
  0% {
    left: 80px;
  }
  100% {
    left: 0;
  }
}
@keyframes animation-arrow {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
@keyframes shine {
  from {
    -webkit-mask-position: 150%;
  }
  to {
    -webkit-mask-position: -50%;
  }
}
@keyframes first {
  0% {
    fill: transparent;
  }
  20% {
    fill: transparent;
  }
  80% {
    fill: #FFDE22;
  }
  100% {
    fill: #FFDE22;
  }
}
@keyframes second {
  0% {
    fill: transparent;
  }
  40% {
    fill: transparent;
  }
  80% {
    fill: #12343B;
  }
  100% {
    fill: #12343B;
  }
}
@keyframes third {
  0% {
    fill: transparent;
  }
  60% {
    fill: transparent;
  }
  80% {
    fill: #2D545E;
  }
  100% {
    fill: #2D545E;
  }
}
@keyframes animateBar {
  0% {
    transform: translate(-100%, -10px);
  }
  100% {
    transform: translate(0, -10px);
  }
}
@keyframes animateBarTransparent {
  0% {
    transform: translate(-100%, 10px);
  }
  100% {
    transform: translate(0, 10px);
  }
}
@keyframes photoOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.17;
  }
}
@keyframes pageWrap {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* PAGE */
.navbar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  z-index: 1000;
  background-color: #12343B;
  border-bottom: 2px solid #2D545E;
  padding: 0;
  transition: 0.2s background-color ease-in-out, 0.2s height ease-in-out;
}
.navbar.nav-wrap.main {
  display: none;
}
.navbar.nav-wrap.mobile .navWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 100%;
  height: 60px;
  transition: 0.2s height ease-in-out;
  padding: 0 20px;
}
.navbar.nav-wrap.mobile .navWrapper .brand .navbar-brand {
  margin-right: 0;
  padding: 8px;
}
.navbar.nav-wrap.mobile .navWrapper .navbar-toggler {
  position: absolute;
  right: 16px;
  border: none;
  color: #FFDE22;
  font-size: 16px;
  letter-spacing: 2px;
  font-weight: 600;
  padding: 8px 16px;
  z-index: 5000;
}
.navbar.nav-wrap.mobile .navbar-collapse {
  background-color: #2D545E;
}
.navbar.nav-wrap.mobile .navbar-collapse .navbar-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: calc(100vh - 60px);
}
.navbar.nav-wrap.mobile .navbar-collapse .navbar-nav .nav-item {
  flex: 0 1 auto;
  padding: 12px;
}
.navbar.nav-wrap.mobile .navbar-collapse .navbar-nav .nav-item .before {
  position: absolute;
  left: -8px;
  bottom: 24px;
  fill: transparent;
  transition: 0.25s fill ease-in-out;
}
.navbar.nav-wrap.mobile .navbar-collapse .navbar-nav .nav-item span {
  color: #F9F9F9;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 600;
  text-transform: lowercase;
  letter-spacing: 1px;
}
.navbar.nav-wrap.mobile .navbar-collapse .navbar-nav .nav-item:hover .before,
.navbar.nav-wrap.mobile .navbar-collapse .navbar-nav .nav-item:focus .before,
.navbar.nav-wrap.mobile .navbar-collapse .navbar-nav .nav-item.active .before {
  fill: #FFDE22;
}
@media (min-width: 992px) {
  .navbar.nav-wrap.mobile {
    display: none;
  }
  .navbar.nav-wrap.main {
    display: block;
    min-width: 100%;
    background-color: transparent;
    border: none;
    height: 80px;
  }
  .navbar.nav-wrap.main .navWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    transition: 0.2s height ease-in-out;
    padding: 0 20px;
  }
  .navbar.nav-wrap.main .navWrapper .navbar-collapse {
    margin-right: 40px;
  }
  .navbar.nav-wrap.main .navWrapper .navbar-collapse .navbar-nav {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0;
  }
  .navbar.nav-wrap.main .navWrapper .navbar-collapse .navbar-nav .nav-item {
    flex: 0 1 auto;
    padding: 8px;
  }
  .navbar.nav-wrap.main .navWrapper .navbar-collapse .navbar-nav .nav-item span {
    color: #F9F9F9;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-weight: 600;
    text-transform: lowercase;
    letter-spacing: 1px;
  }
  .navbar.nav-wrap.main .navWrapper .navbar-collapse .navbar-nav .nav-item .before {
    position: absolute;
    left: -8px;
    bottom: 24px;
    fill: transparent;
    transition: 0.25s fill ease-in-out;
  }
  .navbar.nav-wrap.main .navWrapper .navbar-collapse .navbar-nav .nav-item + .nav-item {
    margin-left: 32px;
  }
  .navbar.nav-wrap.main .navWrapper .navbar-collapse .navbar-nav .nav-item:hover .before,
  .navbar.nav-wrap.main .navWrapper .navbar-collapse .navbar-nav .nav-item:focus .before,
  .navbar.nav-wrap.main .navWrapper .navbar-collapse .navbar-nav .nav-item.active .before {
    fill: #FFDE22;
  }
}
/* COMPONENTS */
.comp__heading {
  margin-bottom: 32px;
}
.comp__heading .heading {
  display: inline-block;
  font-family: "Playfair Display", serif;
  font-size: 48px;
  line-height: 1.1;
  margin: 0;
  letter-spacing: -3px;
}
.comp__heading .heading.heading--yellow {
  color: #FFDE22;
}
.comp__heading .heading.heading--lightPetrol {
  color: #2D545E;
}
.comp__heading .heading.heading--darkPetrol {
  color: #12343B;
}
@media (min-width: 992px) {
  .comp__heading {
    margin-bottom: 0;
  }
  .comp__heading .heading {
    font-size: 64px;
  }
}
.comp__cta {
  padding: 50px 0;
}
.comp__cta .content p {
  padding: 32px 0;
}
.comp__cta .content .btn {
  padding: 16px 32px;
}
@media (min-width: 992px) {
  .comp__cta {
    padding: 100px 0;
  }
  .comp__cta .content p {
    padding: 64px 0;
  }
  .comp__cta .content .btn {
    padding: 32px 64px;
  }
}
.comp__contactForm {
  margin-top: 128px;
}
.comp__contactForm .items {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.comp__contactForm .items .item {
  flex: 0 0 100%;
  margin-bottom: 32px;
}
.comp__contactForm .items .item input,
.comp__contactForm .items .item textarea {
  padding: 4px 16px;
  font-size: 16px;
  width: 100%;
  display: block;
  border-radius: 16px;
  color: #2D545E;
  resize: none;
  outline: none;
  border: none;
  transition: all 0.2s ease-in-out;
  background-color: #f0f0f0;
  text-shadow: 1px 1px 0 #c6c6c6;
  font-family: "Montserrat", sans-serif;
  box-shadow: -5px -5px 20px #fff, 5px 5px 20px #c6c6c6;
}
.comp__contactForm .items .item input:hover,
.comp__contactForm .items .item textarea:hover {
  box-shadow: -2px -2px 5px #fff, 2px 2px 5px #c6c6c6;
}
.comp__contactForm .items .item input:focus,
.comp__contactForm .items .item textarea:focus {
  box-shadow: inset 1px 1px 2px #c6c6c6, inset -1px -1px 2px #fff;
}
.comp__contactForm .items .item .labels {
  display: flex;
}
.comp__contactForm .items .item .labels label {
  font-size: 16px;
  line-height: 1;
  display: block;
  color: #2D545E;
}
.comp__contactForm .items .item .labels label:after {
  content: '*';
  margin-left: 4px;
}
.comp__contactForm .items .item .labels .errorMessage {
  line-height: 1.5;
  font-size: 12px;
  color: red;
  margin-left: 6px;
}
.comp__contactForm .items .item.item--2cols {
  flex: 0 0 100%;
}
.comp__contactForm .btn.btn--lightWhite {
  padding: 16px 48px;
  color: #2D545E;
  margin-top: 16px;
}
@media (min-width: 768px) {
  .comp__contactForm .items {
    flex-wrap: nowrap;
  }
  .comp__contactForm .items .item.item--2cols {
    flex: 0 0 50%;
    flex-basis: calc(50% - 16px);
  }
}
@media (min-width: 992px) {
  .comp__contactForm {
    margin-top: 16px;
  }
}
/* PLUGINS */
.tpl__footer .tpl {
  background-color: #1C1D21;
  padding: 16px 0;
}
.tpl__footer .tpl .content p {
  font-size: 12px;
  font-weight: 600;
  text-align: center;
}
.tpl__footer .tpl .content .before {
  fill: #FFDE22;
  margin: 0 8px;
}
.tpl__mainHeader .tpl {
  min-height: 132vh;
  background: #12343B;
}
.tpl__mainHeader .tpl .title {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  transform: translate(0%, 30%);
  z-index: 60;
  pointer-events: none;
}
.tpl__mainHeader .tpl .title .before {
  fill: #FFDE22;
  transition: 0.25s fill ease-in-out;
  transform: translateY(-20px);
  width: 12px;
  height: 100px;
}
.tpl__mainHeader .tpl .title h1 {
  display: none;
}
.tpl__mainHeader .tpl .title .mainTitle {
  font-size: 48px;
  color: #F9F9F9;
  font-family: "Montserrat", sans-serif, serif;
  font-weight: 800;
  line-height: 1;
  letter-spacing: -2px;
  text-transform: lowercase;
  text-shadow: 2px 2px 8px #2D545E, -2px -2px 8px #2D545E;
  mask-image: linear-gradient(-75deg, rgba(255, 255, 255, 0.6) 30%, #fff 50%, rgba(255, 255, 255, 0.6) 70%);
  mask-size: 200%;
  animation: shine 2s ease-in-out infinite;
  padding: 0 6px;
  user-select: none;
}
.tpl__mainHeader .tpl .title .letter {
  mix-blend-mode: soft-light;
}
.tpl__mainHeader .tpl .title .letter + .letter {
  margin-left: -3px;
}
.tpl__mainHeader .tpl .socialIcons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  position: absolute;
  left: 16px;
  top: 0;
  bottom: 0;
  z-index: 4;
}
.tpl__mainHeader .tpl .socialIcons .social {
  line-height: 1;
  padding: 4px;
}
.tpl__mainHeader .tpl .socialIcons .social + .social {
  margin-top: 8px;
}
.tpl__mainHeader .tpl .socialIcons .social svg {
  width: 16px;
  height: 16px;
  fill: #F9F9F9;
}
.tpl__mainHeader .tpl .content {
  min-height: 132vh;
  width: 100%;
  user-select: none;
}
.tpl__mainHeader .tpl .content .photo {
  position: absolute;
  bottom: 0;
  left: 20px;
  right: 20px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right center;
  height: 120vh;
  z-index: 2;
  animation: 1.5s photoOpacity ease-in-out;
  opacity: 0.17;
}
.tpl__mainHeader .tpl .content .logoWrapper {
  height: 100vh;
}
.tpl__mainHeader .tpl .content .logoWrapper .logo {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 80vh;
  transform: translate(-20%, 20%);
}
.tpl__mainHeader .tpl .content .nameWrapper {
  position: absolute;
  bottom: 8vh;
  z-index: 3;
}
.tpl__mainHeader .tpl .content .nameWrapper .name {
  font-size: 50px;
  color: transparent;
  -webkit-text-stroke: 1px #FFDE22;
  font-family: 'Helvetica', serif;
  font-weight: 900;
  line-height: 0.9;
  letter-spacing: 0;
  pointer-events: none;
}
@media (min-width: 500px) {
  .tpl__mainHeader .tpl .title .mainTitle {
    font-size: 64px;
    letter-spacing: -4px;
    padding: 0 10px;
  }
  .tpl__mainHeader .tpl .title .before {
    width: 16px;
  }
  .tpl__mainHeader .tpl .content .photo {
    transform: translate(-3%, 0%);
    left: 0;
    right: 0;
  }
  .tpl__mainHeader .tpl .content .nameWrapper .name {
    font-size: 85px;
    -webkit-text-stroke: 2px #FFDE22;
    letter-spacing: 0;
  }
  .tpl__mainHeader .tpl .socialIcons {
    left: 32px;
  }
  .tpl__mainHeader .tpl .scroll {
    display: inline-flex;
    align-items: center;
    height: 100vh;
    position: absolute;
    left: -50px;
    bottom: 0;
    text-decoration: none;
    transform: rotate(-90deg);
  }
  .tpl__mainHeader .tpl .scroll .scroll__arrow {
    display: inline-flex;
  }
  .tpl__mainHeader .tpl .scroll .scroll__arrow span {
    position: relative;
    width: 14px;
    height: 2px;
    border-radius: 2px;
    overflow: hidden;
    background: #2D545E;
    z-index: 20;
  }
  .tpl__mainHeader .tpl .scroll .scroll__arrow span:nth-child(1) {
    transform-origin: left bottom;
    transform: rotate(45deg) translate3d(8px, -10px, 0);
  }
  .tpl__mainHeader .tpl .scroll .scroll__arrow span:nth-child(2) {
    transform-origin: left bottom;
    transform: rotate(-45deg);
  }
  .tpl__mainHeader .tpl .scroll .scroll__arrow span:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    width: 0;
    height: 2px;
    background: #F9F9F9;
    animation: animation-arrow 1.5s infinite;
    animation-delay: 1.5s;
  }
  .tpl__mainHeader .tpl .scroll .scroll__text {
    color: #F9F9F9;
    font-family: "Playfair Display", serif;
    font-size: 16px;
    text-transform: lowercase;
  }
  .tpl__mainHeader .tpl .scroll .scroll__line {
    position: relative;
    margin-left: -14px;
    margin-right: 30px;
    width: 70px;
    height: 2px;
    background: #2D545E;
    overflow: hidden;
    z-index: 10;
  }
  .tpl__mainHeader .tpl .scroll .scroll__line:after {
    content: '';
    display: block;
    position: absolute;
    left: 80px;
    width: 70px;
    height: 2px;
    background: #F9F9F9;
    animation: animation-line 1.5s infinite;
  }
}
@media (min-width: 768px) {
  .tpl__mainHeader .tpl .title .mainTitle {
    font-size: 100px;
  }
  .tpl__mainHeader .tpl .title .letter + .letter {
    margin-left: -11px;
  }
  .tpl__mainHeader .tpl .title .before {
    width: 24px;
    transform: translateY(-24px);
  }
  .tpl__mainHeader .tpl .content .nameWrapper {
    bottom: 12vh;
  }
  .tpl__mainHeader .tpl .content .nameWrapper .name {
    font-size: 100px;
    white-space: nowrap;
    -webkit-text-stroke: 2px #FFDE22;
    letter-spacing: -3px;
  }
}
@media (min-width: 992px) {
  .tpl__mainHeader .tpl .title {
    transform: translate(-5%, 30%);
  }
  .tpl__mainHeader .tpl .title .mainTitle {
    font-size: 128px;
    padding: 0 20px;
  }
  .tpl__mainHeader .tpl .title .letter + .letter {
    margin-left: -16px;
  }
  .tpl__mainHeader .tpl .title .before {
    width: 30px;
    transform: translateY(-30px);
  }
  .tpl__mainHeader .tpl .content .nameWrapper .name {
    font-size: 128px;
    -webkit-text-stroke: 3px #FFDE22;
    letter-spacing: -7px;
  }
}
@media (min-width: 1200px) {
  .tpl__mainHeader .tpl .title {
    transform: translate(-15%, 30%);
  }
}
@media (min-width: 1400px) {
  .tpl__mainHeader .tpl .content .nameWrapper .name {
    font-size: 200px;
  }
}
.logo .first {
  animation: first ease-out 2.5s infinite;
}
.logo .second {
  animation: second ease-out 2.5s infinite;
}
.logo .third {
  animation: third ease-out 2.5s infinite;
}
.tpl__textBlock .tpl {
  padding: 120px 0;
  background-color: #12343B;
}
@media (min-width: 992px) {
  .tpl__textBlock .tpl {
    padding: 240px 0;
  }
}
.tpl__projects .tpl {
  padding: 120px 0;
  background-color: #1C1D21;
}
.tpl__projects .tpl .moreItems {
  color: #F9F9F9;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 1.5;
  margin: 128px auto;
}
.tpl__projects .tpl .item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 330px;
  margin-top: 128px;
}
.tpl__projects .tpl .item .bg {
  height: 330px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0.7;
}
.tpl__projects .tpl .item .wrapper {
  position: absolute;
}
.tpl__projects .tpl .item .wrapper .title {
  font-size: 36px;
  font-family: "Playfair Display", serif;
  color: #F9F9F9;
  margin-bottom: 5px;
  line-height: 1;
  letter-spacing: -2px;
}
.tpl__projects .tpl .item .wrapper .period {
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  color: #FFDE22;
  font-weight: 600;
  line-height: 1.5;
}
.tpl__projects .tpl .item .wrapper .subtitle {
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  color: #F9F9F9;
  line-height: 1.5;
}
.tpl__projects .tpl .item .wrapper .btn.btn--lightBlack {
  padding: 12px 24px;
  letter-spacing: 0;
  margin-top: 20px;
  font-size: 14px;
}
.tpl__projects .tpl .item .wrapper .btn.btn--lightBlack svg {
  width: 16px;
  height: 16px;
  fill: #F9F9F9;
}
.tpl__projects .tpl .item video {
  position: absolute;
  max-width: 100%;
  opacity: 0.7;
}
.tpl__projects .tpl .item.item--bigger {
  height: 400px;
}
.tpl__projects .tpl .item.item--bigger .bg {
  height: 400px;
}
.tpl__projects .tpl .item.item--bigger .wrapper .title.title--bigger {
  font-size: 48px;
  margin-bottom: 30px;
}
.tpl__projects .tpl .item.item--bigger .wrapper .subtitle.subtitle--bigger {
  font-size: 20px;
}
@media (min-width: 500px) {
  .tpl__projects .tpl .item .wrapper {
    transform: translateX(-100px);
  }
  .tpl__projects .tpl .item .wrapper .title {
    font-size: 48px;
    letter-spacing: -3px;
    margin-bottom: 15px;
  }
  .tpl__projects .tpl .item .wrapper .btn.btn--lightBlack {
    padding: 14px 32px;
    margin-top: 32px;
  }
  .tpl__projects .tpl .item.item--bigger .wrapper {
    transform: translateX(-100px);
  }
  .tpl__projects .tpl .item.item--bigger .wrapper .title.title--bigger {
    font-size: 72px;
  }
}
@media (min-width: 768px) {
  .tpl__projects .tpl .moreItems {
    margin: 256px auto;
  }
  .tpl__projects .tpl .item {
    height: 500px;
  }
  .tpl__projects .tpl .item .bg {
    height: 500px;
  }
  .tpl__projects .tpl .item .wrapper .title {
    font-size: 64px;
  }
  .tpl__projects .tpl .item.item--bigger {
    height: 700px;
  }
  .tpl__projects .tpl .item.item--bigger .bg {
    height: 700px;
  }
  .tpl__projects .tpl .item.item--bigger .wrapper {
    transform: translateX(-170px);
  }
  .tpl__projects .tpl .item.item--bigger .wrapper .title.title--bigger {
    font-size: 128px;
  }
}
@media (min-width: 992px) {
  .tpl__projects .tpl {
    padding: 240px 0;
  }
  .tpl__projects .tpl .item .wrapper .subtitle {
    font-size: 18px;
  }
  .tpl__projects .tpl .item.item--bigger .wrapper .title {
    max-width: 50%;
  }
  .tpl__projects .tpl .item.item--bigger .wrapper .subtitle.subtitle--bigger {
    font-size: 24px;
  }
}
@media (min-width: 1200px) {
  .tpl__projects .tpl .item {
    margin-top: 250px;
  }
  .tpl__projects .tpl .item .wrapper {
    transform: translateX(-170px);
  }
  .tpl__projects .tpl .item .wrapper .subtitle {
    max-width: 30%;
  }
  .tpl__projects .tpl .item video {
    opacity: 1;
  }
  .tpl__projects .tpl .item .bg {
    opacity: 1;
  }
  .tpl__projects .tpl .item.item--bigger .wrapper {
    transform: translateX(-270px);
  }
}
.tpl__skills .tpl {
  background-color: #2D545E;
  padding: 120px 0 56px;
}
.tpl__skills .tpl .items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 0 64px;
}
.tpl__skills .tpl .items .item {
  flex: 0 0 100%;
  flex-basis: 100%;
  color: #F9F9F9;
  font-size: 16px;
  margin-top: 24px;
  overflow: auto;
}
.tpl__skills .tpl .items .item .title {
  color: #F9F9F9;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 1;
}
.tpl__skills .tpl .items .item .progress {
  background-color: #12343B;
  border-radius: unset;
  height: 18px;
  overflow: unset;
}
.tpl__skills .tpl .items .item .progress .progress-bar {
  background-color: #F9F9F9;
  overflow: inherit;
  animation: animateBar 2s ease-in-out;
  transform: translateY(-10px);
}
.tpl__skills .tpl .items .item .progress.progress--transparent {
  background-color: #2D545E;
}
.tpl__skills .tpl .items .item .progress.progress--transparent .progress-bar {
  transform: translateY(10px);
  animation: animateBarTransparent 2s ease-in-out;
}
@media (min-width: 500px) {
  .tpl__skills .tpl .items .item {
    flex-basis: calc(50% - 24px);
    margin-top: 32px;
  }
  .tpl__skills .tpl .items .item .progress {
    height: 20px;
  }
}
@media (min-width: 768px) {
  .tpl__skills .tpl .items .item {
    flex-basis: calc(50% - 48px);
  }
  .tpl__skills .tpl .items .item .title {
    line-height: 1.5;
  }
}
@media (min-width: 992px) {
  .tpl__skills .tpl {
    padding: 240px 0;
  }
  .tpl__skills .tpl .items .item {
    flex-basis: calc(50% - 64px);
  }
  .tpl__skills .tpl .items .item .title {
    font-size: 20px;
  }
}
@media (min-width: 992px) {
  .tpl__skills .tpl .items {
    margin: 64px 0 96px;
  }
  .tpl__skills .tpl .items .item {
    flex-basis: calc(36% - 64px);
  }
}
@media (min-width: 1200px) {
  .tpl__skills .tpl .items .item {
    flex-basis: calc(33% - 64px);
  }
}
.tpl__contact .tpl {
  background-color: #F9F9F9;
  padding: 100px 0;
}
.tpl__contact .tpl .information {
  padding: 64px 0 16px;
}
.tpl__contact .tpl .information .name {
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  line-height: 1.1;
  color: #2D545E;
}
.tpl__contact .tpl .information .items {
  display: flex;
  flex-direction: column;
  margin: 4px 0;
}
.tpl__contact .tpl .information .items .item {
  font-size: 16px;
  letter-spacing: 0;
  line-height: 1.5;
  margin: 0;
  color: #12343B;
}
.tpl__contact .tpl .information .items .item:hover,
.tpl__contact .tpl .information .items .item:active {
  color: #FFDE22;
}
.tpl__contact .tpl .socialIcons .social {
  line-height: 1;
  padding: 16px;
}
.tpl__contact .tpl .socialIcons .social + .social {
  margin-left: 20px;
}
.tpl__contact .tpl .socialIcons .social svg {
  width: 16px;
  height: 16px;
}
@media (min-width: 992px) {
  .tpl__contact .tpl .information {
    padding: 64px 0;
  }
}
.tpl__notFoundPage .tpl {
  background-color: #1C1D21;
}
.tpl__notFoundPage .tpl .content {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  flex-direction: column;
}
.tpl__notFoundPage .tpl .content p {
  margin: 20px 0 70px;
  text-align: center;
}
.tpl__notFoundPage .tpl .content .title {
  font-size: 128px;
  font-weight: 800;
  line-height: 1;
  letter-spacing: -1px;
  font-family: "Montserrat", sans-serif;
  pointer-events: none;
  color: #1C1D21;
  text-shadow: -5px -5px 15px #33353d, 5px 5px 15px #000;
  padding: 0 20px;
}
@media (min-width: 768px) {
  .tpl__notFoundPage .tpl .content p {
    margin: 50px 0 100px;
  }
  .tpl__notFoundPage .tpl .content .title {
    font-size: 256px;
    letter-spacing: -4px;
  }
}
@media (min-width: 992px) {
  .tpl__notFoundPage .tpl .content .title {
    font-size: 400px;
  }
}
.tpl__loader .tpl {
  height: 100vh;
}
.tpl__loader .tpl .content {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}
.tpl__loader .tpl .content .logoWrapper .logo {
  height: 30vh;
}
