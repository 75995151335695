.tpl__loader {
  .tpl {
    height: 100vh;
    .content {
      display: flex; align-items: center; justify-content: center; min-height: 100vh;
      .logoWrapper {
        .logo { height: 30vh; }
      }
    }
  }
}