.tpl__mainHeader {
  .tpl {
    min-height: 132vh; background: @colorDarktPetrol;
    .title {
      .absoluteFull; display: flex; justify-content: center; align-items: center; height: 100vh; transform: translate(0%, 30%); z-index: 60; pointer-events: none;
      .before { fill: @colorYellow; transition: 0.25s fill ease-in-out; transform: translateY(-20px); width: 12px; height: 100px; }
      h1 { display: none; }
      .mainTitle { font-size: 48px; color: @colorLightWhite; font-family: @font-family-default, serif; font-weight: 800; line-height: 1; letter-spacing: -2px; text-transform: lowercase;
          text-shadow: 2px 2px 8px @colorLightPetrol, -2px -2px 8px @colorLightPetrol; mask-image: linear-gradient(-75deg, rgba(255,255,255,.6) 30%, #fff 50%, rgba(255,255,255,.6) 70%);
          mask-size: 200%; animation: shine 2s ease-in-out infinite; padding: 0 6px; user-select: none; }

      .letter { mix-blend-mode: soft-light; }
      .letter + .letter { margin-left: -3px; }
    }
    .socialIcons {
      display: flex; flex-direction: column; justify-content: center; height: 100vh; position: absolute; left: 16px; top: 0; bottom: 0; z-index: 4;
      .social { line-height: 1; padding: 4px; }
      .social + .social { margin-top: 8px; }
      .social svg { width: 16px; height: 16px; fill: @colorLightWhite; }
    }
    .content {
      min-height: 132vh; width: 100%; user-select: none;
      .photo { position: absolute; bottom: 0; left: 20px; right: 20px; background-repeat: no-repeat; background-size: contain; background-position: right center; height: 120vh; z-index: 2; animation: 1.5s photoOpacity ease-in-out; opacity: 0.17; }
      .logoWrapper {
        height: 100vh;
        .logo { .absoluteFull; width: 100vw; height: 80vh; transform: translate(-20%, 20%); }
      }
      .nameWrapper { position: absolute; bottom: 8vh; z-index: 3; }
      .nameWrapper .name { font-size: 50px; color: transparent; -webkit-text-stroke: 1px @colorYellow; font-family: 'Helvetica', serif; font-weight: 900; line-height: 0.9; letter-spacing: 0; pointer-events: none; }
    }
  }

  @media @media-s-min {
    .tpl {
      .title {
        .mainTitle { font-size: 64px; letter-spacing: -4px; padding: 0 10px; }
        .before { width: 16px; }
      }
      .content {
        .photo { transform: translate(-3%, 0%); left: 0; right: 0; }
        .nameWrapper .name { font-size: 85px; -webkit-text-stroke: 2px @colorYellow; letter-spacing: 0; }
      }
      .socialIcons { left: 32px; }
      .scroll {
        display: inline-flex; align-items: center; height: 100vh; position: absolute; left: -50px; bottom: 0; text-decoration: none; transform: rotate(-90deg);
        .scroll__arrow {
          display: inline-flex;
          span { position: relative; width: 14px; height: 2px; border-radius: 2px; overflow: hidden; background: @colorLightPetrol; z-index: 20;
            &:nth-child(1) { transform-origin: left bottom; transform: rotate(45deg) translate3d(8px, -10px, 0); }
            &:nth-child(2) { transform-origin: left bottom; transform: rotate(-45deg); }
            &:after { content: ''; display: block; position: absolute; left: 0; width: 0; height: 2px; background: @colorLightWhite; animation: animation-arrow 1.5s infinite; animation-delay: 1.5s;  }
          }
        }
        .scroll__text { color: @colorLightWhite; font-family: @font-family-second; font-size: 16px; text-transform: lowercase; }
        .scroll__line { position: relative; margin-left: -14px; margin-right: 30px; width: 70px; height: 2px; background: @colorLightPetrol; overflow: hidden; z-index: 10;
          &:after { content: ''; display: block; position: absolute; left: 80px; width: 70px; height: 2px; background: @colorLightWhite; animation: animation-line 1.5s infinite; }
        }
      }
    }
  }

  @media @media-m-min {
    .tpl {
      .title {
        .mainTitle { font-size: 100px; }
        .letter + .letter { margin-left: -11px; }
        .before { width: 24px; transform: translateY(-24px); }
      }
      .content .nameWrapper {
        bottom: 12vh;
        .name { font-size: 100px; white-space: nowrap; -webkit-text-stroke: 2px @colorYellow; letter-spacing: -3px; }
      }
    }
  }

  @media @media-l-min {
    .tpl {
      .title {
        transform: translate(-5%, 30%);
        .mainTitle { font-size: 128px; padding: 0 20px; }
        .letter + .letter { margin-left: -16px; }
        .before { width: 30px; transform: translateY(-30px); }
      }
      .content .nameWrapper .name { font-size: 128px; -webkit-text-stroke: 3px @colorYellow; letter-spacing: -7px; }
    }
  }

  @media @media-xl-min {
    .tpl {
      .title { transform: translate(-15%, 30%); }
    }
  }

  @media @media-xxl-min {
    .tpl {
      .content .nameWrapper .name { font-size: 200px; }
    }
  }
}

.logo {
  .first { animation: first ease-out 2.5s infinite; }
  .second { animation: second ease-out 2.5s infinite; }
  .third { animation: third ease-out 2.5s infinite; }
}